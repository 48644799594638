import api from '../../axios';

export default {
  list: () => {
    return api({
      url: `/business/art`,
      method: `GET`,
    });
  },
  inactive: () => {
    return api({
      url: `/business/art/inactive`,
      method: `GET`,
    });
  },
  search: (term, active) => {
    return api({
      url: `/business/art/search`,
      method: `GET`,
      params: { term, active },
    });
  },
  create: (data) => {
    return api({
      url: `/business/art/create`,
      method: `POST`,
      data: data,
    });
  },
  details: (id) => {
    return api({
      url: `/business/art/details/${id}`,
      method: `GET`,
    });
  },
  thumbnailUpload: (data) => {
    return api({
      url: `/business/art/thumbnail/upload`,
      method: `POST`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  variantCreate: (data) => {
    return api({
      url: `/business/art/variant/create`,
      method: `POST`,
      data: data,
    });
  },
  variantUpdate: (data) => {
    return api({
      url: `/business/art/variant/update`,
      method: `POST`,
      data: data,
    });
  },
  variantDelete: (data) => {
    return api({
      url: `/business/art/variant/delete`,
      method: `POST`,
      data: data,
    });
  },
  dimensionCreate: (data) => {
    return api({
      url: `/business/art/dimension/create`,
      method: `POST`,
      data: data,
    });
  },
  dimensionUpdate: (data) => {
    return api({
      url: `/business/art/dimension/update`,
      method: `POST`,
      data: data,
    });
  },
  dimensionDelete: (data) => {
    return api({
      url: `/business/art/dimension/delete`,
      method: `POST`,
      data: data,
    });
  },
  imageDelete: (data) => {
    return api({
      url: `/business/art/image/delete`,
      method: `POST`,
      data: data,
    });
  },
  update: (data) => {
    return api({
      url: `/business/art/update`,
      method: `PATCH`,
      data: data,
    });
  },
};
