import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Input = ({ id, label, error, showMessage, disabled, required, ...props }) => {
  return (
    <div className="input">
      <div className="flex justify-between items-center mb-1">
        <label htmlFor={id} className="text-sm font-bold text-gray-800 flex items-center">
          {label}{' '}
          {required && (
            <span className="text-red-600" style={{ marginLeft: '2px' }}>
              *
            </span>
          )}
        </label>
        {showMessage && <p className="text-red-600 text-xs text-right font-semibold">{error}</p>}
      </div>
      <input
        {...props}
        className={classNames(
          'block p-3 border rounded bg-gray-200 placeholder-gray-600',
          error ? 'border-solid border-red-600' : 'border-transparent'
        )}
        style={{ width: '-webkit-fill-available' }}
        disabled={disabled}
      ></input>
    </div>
  );
};

Input.defaultProps = {
  error: null,
  showMessage: true,
};

Input.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  showMessage: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default Input;
