import api from '../../axios';

export default {
  highlight: () => {
    return api({
      url: `/business/case/highlight`,
      method: `GET`,
    });
  },
  list: (category, type) => {
    return api({
      url: `/business/case/list/${category}/${type}`,
      method: `GET`,
    });
  },
  inactive: (category, type) => {
    return api({
      url: `/business/case/inactive/${category}/${type}`,
      method: `GET`,
    });
  },
  search: (category, type, term, active) => {
    return api({
      url: `/business/case/search`,
      method: `GET`,
      params: { category, type, term, active },
    });
  },
  create: (data) => {
    return api({
      url: `/business/case/create`,
      method: `POST`,
      data: data,
    });
  },
  details: (caseID) => {
    return api({
      url: `/business/case/details/${caseID}`,
      method: `GET`,
    });
  },
  highlightInactive: (category, type) => {
    return api({
      url: `/business/case/highlight/inactive/${category}/${type}`,
      method: `GET`,
    });
  },
  highlightAdd: (data) => {
    return api({
      url: `/business/case/highlight/add`,
      method: `POST`,
      data: data,
    });
  },
  highlightDelete: (data) => {
    return api({
      url: `/business/case/highlight/delete`,
      method: `POST`,
      data: data,
    });
  },
  thumbnailUpload: (data) => {
    return api({
      url: `/business/case/thumbnail/upload`,
      method: `POST`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  dimensionCreate: (data) => {
    return api({
      url: `/business/case/dimension/create`,
      method: `POST`,
      data: data,
    });
  },
  dimensionUpdate: (data) => {
    return api({
      url: `/business/case/dimension/update`,
      method: `POST`,
      data: data,
    });
  },
  dimensionDelete: (data) => {
    return api({
      url: `/business/case/dimension/delete`,
      method: `POST`,
      data: data,
    });
  },
  timelineCreate: (data) => {
    return api({
      url: `/business/case/timeline/create`,
      method: `POST`,
      data: data,
    });
  },
  timelineUpdate: (data) => {
    return api({
      url: `/business/case/timeline/update`,
      method: `POST`,
      data: data,
    });
  },
  timelineDelete: (data) => {
    return api({
      url: `/business/case/timeline/delete`,
      method: `POST`,
      data: data,
    });
  },
  imageDelete: (data) => {
    return api({
      url: `/business/case/image/delete`,
      method: `POST`,
      data: data,
    });
  },
  update: (data) => {
    return api({
      url: `/business/case/update`,
      method: `PATCH`,
      data: data,
    });
  },
};
