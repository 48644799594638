import React, { Fragment } from 'react';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

const Digit = ({ id, label, value, placeholder, mask, error, onChange, required }) => {
  return (
    <Fragment>
      <div className="flex justify-between items-center mb-1">
        <label htmlFor={id} className="block text-sm font-bold text-gray-800">
          {label}
          {required && (
            <span className="text-red-600" style={{ marginLeft: '2px' }}>
              *
            </span>
          )}
        </label>
        <p className="text-red-600 text-xs text-right font-semibold">{error}</p>
      </div>
      <InputMask mask={mask} maskChar="" value={value} onChange={(event) => onChange(event.target.value)}>
        {(inputProps) => (
          <input
            {...inputProps}
            id={id}
            className={classNames(
              'block p-3 rounded border bg-gray-200 placeholder-gray-600 cursor-pointer flex-grow',
              error ? 'border-solid border-red-600' : 'border-transparent'
            )}
            placeholder={placeholder}
            style={{ width: '-webkit-fill-available' }}
          ></input>
        )}
      </InputMask>
    </Fragment>
  );
};

Digit.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default Digit;
