import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

// Components
import MaterialLoader from '../../../loader/materialLoader';

// Actions
import { notificationSuccess, notificationError } from '../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../services/firebase';

// API Services
import TestimonialAPI from '../../../../services/api/business/testimonials';

const Video = ({ id, video, notificationSuccess, notificationError }) => {
  const [loading, setLoading] = useState(false);

  // API Calls
  const videoUpload = async (data) => {
    setLoading(true);

    try {
      const response = await TestimonialAPI.videoUpload(data);
      const { message } = response.data;

      setLoading(false);
      notificationSuccess(message);
      dispatch(process.env.REACT_APP_TESTIMONIAL_LIST_REFRESH);
      dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          videoUpload(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const videoDelete = async (id) => {
    setLoading(true);

    try {
      const response = await TestimonialAPI.videoDelete({
        id,
      });
      const { message } = response.data;

      setLoading(false);
      notificationSuccess(message);
      dispatch(process.env.REACT_APP_TESTIMONIAL_LIST_REFRESH);
      dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          videoDelete(id);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="pt-5 pb-3 relative">
      <div className="absolute" style={{ right: '15px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE)}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
      <h1 className="text-xl text-gray-800 text-center mb-4 font-semibold">Video Studio</h1>
      {loading && (
        <div className="absolute bottom-0">
          <MaterialLoader color="black" />
        </div>
      )}
      <ReactPlayer url={video} playing={true} controls={true} width="100%" height="100%"></ReactPlayer>
      <div className="flex justify-between mt-3 mb-4">
        <button
          className={
            'border-0 py-3 px-3 shadow-md bg-black text-white text-xs font-semibold opacity-100 cursor-pointer'
          }
          onClick={() => {}}
        >
          <input
            accept="video/*"
            hidden
            id="video"
            type="file"
            onChange={(event) => {
              const file = event.target.files[0];

              const data = new FormData();
              data.append('id', id);
              data.append('video', file);

              videoUpload(data);
            }}
          />
          <label htmlFor="video" type="button">
            <div className="flex items-center justify-center cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                ></path>
              </svg>
              <span>Upload New</span>
            </div>
          </label>
        </button>
        {video && (
          <button
            className={'border-0 py-3 px-3 shadow-md bg-black text-white text-xs font-semibold opacity-100'}
            onClick={() => videoDelete(id)}
          >
            <div className="flex items-center justify-center cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                ></path>
              </svg>
              <span>Delete</span>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Video.propTypes = {
  id: PropTypes.string,
  video: PropTypes.string,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Video);
