import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Components
import MaterialLoader from '../../loader/materialLoader';

const LoadingButton = ({ label, type, width, fullWidth, onClick, loading, position, size, borderRadius, disabled }) => {
  return (
    <button
      className={classNames(
        'border-0 py-3 shadow-md text-center text-white bg-black font-semibold',
        borderRadius && 'rounded-md',
        fullWidth ? 'w-full' : width,
        position
      )}
      type={type || 'submit'}
      disabled={disabled}
      onClick={onClick}
    >
      {loading ? (
        <MaterialLoader size={size === 'large' ? 'midway' : 'small'} />
      ) : (
        <span className={classNames(size === 'large' && 'text-lg')}>{label}</span>
      )}
    </button>
  );
};

LoadingButton.defaultProps = {
  borderRadius: true,
  size: 'normal',
};

LoadingButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.string,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  position: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'large']),
  borderRadius: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default LoadingButton;
