/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import { useParams, useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import FileViewer from 'react-file-viewer';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Components
import MaterialLoader from '../../../../../components/loader/materialLoader';
import Dropdown from '../../../../../components/custom/dropdown/general';

// Actions
import { notificationSuccess, notificationError } from '../../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../../services/firebase';

// API Services
import CareerAPI from '../../../../../services/api/business/career';

// Options
const options = [
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'closed',
    name: 'Closed',
  },
];

const CareerDetails = ({ notificationSuccess, notificationError }) => {
  const { id } = useParams();

  const history = useHistory();

  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    careerDetails(true, id);
  }, []);

  // API Calls
  const careerDetails = async (loader, id) => {
    if (loader) {
      setLoading(true);
    }

    try {
      const response = await CareerAPI.details(id);
      const result = response.data;
      setDetails(result);
      setLoading(false);
      dispatch({
        type: process.env.REACT_APP_CHANGE_PAGE_TITLE,
        payload: {
          title: 'Career Form',
        },
      });
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          careerDetails(null, id);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const careerUpdate = async (state) => {
    try {
      const response = await CareerAPI.update({
        id,
        state,
      });
      const { message } = response.data;

      notificationSuccess(message);
      history.goBack();
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          careerUpdate(state);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="career-details pt-32">
      {loading ? (
        <div className="absolute top-0 left-0 flex items-center w-full h-screen">
          <MaterialLoader color="black" size="large" />
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-12 sm:col-span-6">
                <div className="w-full block">
                  <div className="flex flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      onClick={() => history.goBack()}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16l-4-4m0 0l4-4m-4 4h18"
                      ></path>
                    </svg>
                    <p className="text-sm mt-4">
                      Status:{' '}
                      <span className="font-bold" style={{ color: details.active ? 'green' : 'red' }}>
                        {details.active ? 'Active' : 'Closed'}
                      </span>
                    </p>
                    <p className="text-sm mt-2">
                      Date: <span className="font-bold">{details.date}</span>
                    </p>
                  </div>
                  <div className="text-left w-full mt-4">
                    <span className="text-sm">Name</span>
                    <div className="flex items-center">
                      <p className="text-lg font-semibold mr-2">{details.name}</p>
                    </div>
                  </div>
                  <div className="text-left w-full mt-4">
                    <span className="text-sm">Email</span>
                    <div className="flex items-center">
                      <p className="text-lg font-semibold mr-2">{details.email}</p>
                    </div>
                  </div>
                  <div className="text-left w-full mt-4">
                    <span className="text-sm">Phone</span>
                    <div className="flex items-center">
                      <p className="text-lg font-semibold mr-2">{details.phone}</p>
                    </div>
                  </div>
                  <div className="text-left w-full mt-4">
                    <span className="text-sm">Message</span>
                    <div className="flex items-center">
                      <p className="text-lg font-semibold mr-2">{details.message}</p>
                    </div>
                  </div>
                  {details.links && (
                    <div className="text-left w-full mt-4">
                      <span className="text-sm">Links</span>
                      <div className="flex items-center">
                        <p className="text-lg font-semibold mr-2">{details.links}</p>
                      </div>
                    </div>
                  )}
                  <div className="h-12 flex flex-col items-start mt-4">
                    <span className="text-sm">Change Status</span>
                    <Dropdown
                      value={details.active ? 'Active' : 'Closed'}
                      options={options}
                      onSelect={(value) => {
                        careerUpdate(value.id === 'active');
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-12 sm:col-span-6">
                {details.file ? (
                  details.file.type === 'video/mp4' || details.file.type === 'audio/mpeg' ? (
                    <div className="mt-0 md:mt-10">
                      <ReactPlayer
                        url={details.file.url}
                        playing={false}
                        controls={true}
                        width="100%"
                        height={details.file.type === 'audio/mpeg' ? '54px' : 'auto'}
                      />
                    </div>
                  ) : (
                    <FileViewer
                      filePath={details.file.url}
                      fileType={
                        details.file.type === 'application/pdf'
                          ? 'pdf'
                          : details.file.type === 'image/png'
                          ? 'png'
                          : 'jpeg'
                      }
                    />
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

CareerDetails.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CareerDetails);
