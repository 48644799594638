import React, { Fragment, useState } from 'react';
import { dispatch } from 'use-bus';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

// Nav List
import NavList from '../../navigation/header/data/navList.json';

const Navigation = ({ session, close }) => {
  const [subMenu, setSubMenu] = useState([]);
  const [open, setOpen] = useState(false);

  return (
    <nav className="small">
      <div className="container">
        <div className="nav-columns">
          <div className="nav-column">
            <ul className="nav-links">
              {open ? (
                <Fragment>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mb-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    onClick={() => {
                      setSubMenu([]);
                      setOpen(false);
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16l-4-4m0 0l4-4m-4 4h18"
                    ></path>
                  </svg>
                  {subMenu.map((item, index) => (
                    <li key={index}>
                      <NavLink
                        to={item.link}
                        exact
                        onClick={() => {
                          close();
                          setTimeout(() => {
                            setOpen(false);
                            setSubMenu([]);
                          }, 1000);
                        }}
                      >
                        {item.title}
                      </NavLink>
                    </li>
                  ))}
                </Fragment>
              ) : (
                <Fragment>
                  {NavList.center.map((item, index) => (
                    <li key={index}>
                      {item.link ? (
                        <NavLink to={item.link} exact onClick={() => close()}>
                          {item.name}
                        </NavLink>
                      ) : (
                        <div
                          className="flex justify-between"
                          role="button"
                          tabIndex="0"
                          onKeyDown={() => {}}
                          onClick={() => {
                            setSubMenu(item.subMenu);
                            setOpen(true);
                          }}
                        >
                          <span>{item.name}</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 md:w-8 md:h-8"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                          </svg>
                        </div>
                      )}
                    </li>
                  ))}
                  {NavList.right.map((item, index) => (
                    <li key={index}>
                      {item.link ? (
                        <NavLink to={item.link} exact onClick={() => close()}>
                          {item.name}
                        </NavLink>
                      ) : (
                        <span>{item.name}</span>
                      )}
                    </li>
                  ))}
                  {session.loading
                    ? null
                    : !session.logged && (
                        <button
                          className="bg-black text-white font-semibold shadow-md rounded-sm border-0 px-6 py-3 cursor-pointer"
                          onClick={() => {
                            close();
                            dispatch({
                              type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                              payload: {
                                modal: process.env.REACT_APP_LOGIN_MODAL,
                              },
                            });
                          }}
                        >
                          Login
                        </button>
                      )}
                </Fragment>
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({});

Navigation.propTypes = {
  session: PropTypes.object,
  close: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
