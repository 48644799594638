/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Schema
import HighlightSchema from './data/schema.json';

// Fields
import Dropdown from '../../fields/dropdown';
import LoadingButton from '../../button/loadingButton';

// Actions
import { notificationSuccess, notificationError } from '../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../services/firebase';

// API Services
import CaseStudyAPI from '../../../services/api/business/caseStudy';

// Options
const categoriesList = [
  {
    id: 'architecture',
    name: 'Architecture',
  },
  {
    id: 'interiors',
    name: 'Interiors',
  },
];

const typesList = [
  {
    id: 'commercial',
    name: 'Commercial',
  },
  {
    id: 'residential',
    name: 'Residential',
  },
  {
    id: 'factories',
    name: 'Factories',
  },
  {
    id: 'institutions',
    name: 'Institutions',
  },
  {
    id: 'masterPlanning',
    name: 'Master Planning',
  },
  {
    id: 'hospitality',
    name: 'Hospitality',
  },
  {
    id: 'healthcare',
    name: 'Healthcare',
  },
];

// Form Validation
const highlightSchema = yup.object().shape({
  category: yup.object().nullable().required(),
  type: yup.object().nullable().required(),
  case: yup.object().nullable().required(),
});

const Highlight = ({ notificationSuccess, notificationError }) => {
  const [category, setCategory] = useState(null);
  const [type, setType] = useState(null);
  const [types, setTypes] = useState(typesList);
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      category: categoriesList[0],
      type: typesList[0],
      case: null,
    },
    resolver: yupResolver(highlightSchema),
  });

  useEffect(() => {
    setCategory(categoriesList[0].id);
    setType(typesList[0].id);

    highlightInactive(categoriesList[0].id, typesList[0].id);
  }, []);

  const onSubmit = (data) => {
    if (data.case?.id) {
      setLoading(true);

      highlightAdd({
        id: data.case.id,
      });
    }
  };

  // API Calls
  const highlightInactive = async (category, type) => {
    try {
      const response = await CaseStudyAPI.highlightInactive(category, type);
      const result = response.data;
      setValue('case', result?.[0] || {});
      setCases(result);
    } catch (error) {
      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          highlightInactive(category, type);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const highlightAdd = async (data) => {
    try {
      const response = await CaseStudyAPI.highlightAdd(data);
      const { message } = response.data;

      setLoading(false);
      notificationSuccess(message);
      dispatch(process.env.REACT_APP_HIGHLIGHT_LIST_REFRESH);
      dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          highlightAdd(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="pt-5 pb-3 relative">
      <div className="absolute" style={{ right: '15px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE)}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
      <h1 className="text-xl text-gray-800 text-center mb-4 font-semibold">Highlight</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
        {HighlightSchema.map((item, index) => {
          return (
            <div key={index} className="mb-4">
              <Controller
                name={item.id}
                control={control}
                render={({ value, onChange }) => {
                  return (
                    <Dropdown
                      label={item.label}
                      name={item.id}
                      options={item.id === 'category' ? categoriesList : item.id === 'type' ? types : cases}
                      value={value}
                      required={item.required}
                      onChange={(value) => {
                        let newList = [];
                        let newCategory = null;
                        let newType = null;

                        onChange(value);

                        if (item.id === 'category') {
                          if (value.id === 'interiors') {
                            const list = [...typesList];
                            newList = list.slice(0, 2);
                          } else {
                            newList = typesList;
                          }

                          newCategory = value.id;
                          newType = type;

                          setCategory(value.id);
                          setTypes(newList);
                        } else if (item.id === 'type') {
                          newCategory = category;
                          newType = value.id;

                          setType(value.id);
                        }

                        if (item.id !== 'case') {
                          highlightInactive(newCategory, newType);
                        }
                      }}
                    ></Dropdown>
                  );
                }}
              ></Controller>
            </div>
          );
        })}
        <LoadingButton label={'Add'} fullWidth loading={loading} disabled={loading}></LoadingButton>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Highlight.propTypes = {
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Highlight);
