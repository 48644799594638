import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './style.scss';

const Switch = ({ label, name, value, onChange }) => {
  return (
    <Fragment>
      <label htmlFor={name} className={classNames('block text-sm font-bold text-gray-800')}>
        {label}
      </label>
      <div className="checkbox" style={{ width: 'fit-content' }}>
        <input
          className="switch"
          type="checkbox"
          name={name}
          checked={value}
          onChange={(event) => onChange(event.target.checked)}
        />
        <div></div>
      </div>
    </Fragment>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Switch;
