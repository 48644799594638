import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { notificationSuccess, notificationError } from '../../../redux/root/actions';

// Components
import Dropdown from '../../../components/custom/dropdown/general';

// Sections
import Careers from './careers';
import Enquiries from './enquiries';

// Options
const options = [
  {
    id: 'career',
    name: 'Career',
  },
  {
    id: 'enquiry',
    name: 'Enquiry',
  },
];

const CareerEnquiry = () => {
  const [page, setPage] = useState(null);

  useEffect(() => {
    const type = localStorage.getItem(process.env.REACT_APP_CAREER_ENQUIRE_TYPE);

    if (type) {
      setPage(JSON.parse(type));
    } else {
      setPage(options[0]);
    }
  }, []);

  return (
    <div className="career-enquire bg-white pt-32">
      <div className="container">
        <div className="row">
          <div className="w-full">
            <div className="h-12 flex items-center justify-between">
              <Dropdown
                value={page?.name}
                options={options}
                onSelect={(value) => {
                  localStorage.setItem(process.env.REACT_APP_CAREER_ENQUIRE_TYPE, JSON.stringify(value));
                  localStorage.removeItem(process.env.REACT_APP_CAREER_ENQUIRE_STATE);

                  setPage(value);
                }}
              />
            </div>
            {page && <div style={{ marginTop: '-48px' }}>{page?.id === 'career' ? <Careers /> : <Enquiries />}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

CareerEnquiry.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CareerEnquiry);
