import React from 'react';
import { dispatch } from 'use-bus';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { notificationSuccess, notificationError } from '../../../../redux/root/actions';

const Shipping = ({ distance, breakdown, shipping, notificationSuccess, notificationError }) => {
  return (
    <div className="pt-5 pb-3">
      <div className="absolute" style={{ right: '15px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE)}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
      <h1 className="text-xl text-gray-800 text-center mb-4 font-semibold">Shipping Breakdown</h1>
      <div className="flex justify-between mt-6">
        <p className="text-sm">Estimated Distance</p>
        <p className="text-sm">{distance} Kms</p>
      </div>
      <div className="flex justify-between mt-2">
        <p className="text-sm">Distance Cost</p>
        <p className="text-sm">&#8377;{breakdown.distance}</p>
      </div>
      {breakdown.art !== 0 && (
        <div className="flex justify-between mt-2">
          <p className="text-sm">Art Cost</p>
          <p className="text-sm">&#8377;{breakdown.art}</p>
        </div>
      )}
      {breakdown.furniture !== 0 && (
        <div className="flex justify-between mt-2">
          <p className="text-sm">Furniture Cost</p>
          <p className="text-sm">&#8377;{breakdown.furniture}</p>
        </div>
      )}
      {breakdown.paint !== 0 && (
        <div className="flex justify-between mt-2">
          <p className="text-sm">Paint Cost</p>
          <p className="text-sm">&#8377;{breakdown.paint}</p>
        </div>
      )}
      <div className="flex justify-between mt-2">
        <p className="text-base font-bold">Total Cost</p>
        <p className="text-base font-bold">&#8377;{shipping}</p>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Shipping.propTypes = {
  distance: PropTypes.number,
  breakdown: PropTypes.object,
  shipping: PropTypes.number,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Shipping);
