import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import PropTypes from 'prop-types';

// Actions
import { notificationSuccess, notificationError } from '../../redux/root/actions';

// Data
import DashboardSchema from '../../data/main/dashboard.json';

const Dashboard = () => {
  const history = useHistory();

  return (
    <div className="dashboard pt-32">
      <div className="container">
        <div className="row">
          <div className="w-full">
            <Masonry
              breakpointCols={{
                default: 4,
                1024: 3,
                768: 2,
                425: 1,
              }}
              className="masonry-grid"
              columnClassName="masonry-grid-column"
            >
              {DashboardSchema.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-center h-20 rounded-md shadow-md cursor-pointer"
                    style={{ backgroundColor: '#fff3d8' }}
                    role="presentation"
                    onClick={() => history.push(item.link)}
                  >
                    <span className="text-black font-bold">{item.title}</span>
                  </div>
                );
              })}
            </Masonry>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Dashboard.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
