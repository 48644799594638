import api from '../../../axios';

export default {
  list: () => {
    return api({
      url: `/master/furniture/type/list`,
      method: `GET`,
    });
  },
};
