/* eslint-disable require-jsdoc */
/* eslint-disable react/no-deprecated */
import { Component } from 'react';
import PropTypes from 'prop-types';

class ProgressiveImage extends Component {
  state = {
    preview: null,
    src: null,
    blur: 0,
  };

  componentWillMount() {
    this.imageLoad();
  }

  componentDidUpdate() {
    const { preview, src } = this.state;
    const { preview: thumbnail, src: url, initialBlur } = this.props;

    if (preview !== null && src !== null) {
      if (preview !== thumbnail && src !== url) {
        this.setState({
          ...this.state,
          preview: null,
          src: thumbnail,
          blur: initialBlur,
        });
        this.fetch(url)
          .then((srcDataURI) => {
            this.setState({ preview: thumbnail, src: srcDataURI, blur: 0 });
            return srcDataURI;
          })
          .catch((error) => {
            throw error;
          });
      }
    }
  }

  imageLoad() {
    const { src, preview } = this.props;
    const initialBlur = this.props.initialBlur;
    this.setState({ src: preview, blur: initialBlur });
    this.fetch(src)
      .then((srcDataURI) => {
        this.setState({ preview: preview, src: srcDataURI, blur: 0 });
        return srcDataURI;
      })
      .catch((error) => {
        throw error;
      });
  }

  fetch(src) {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = src;
      image.addEventListener('load', () => resolve(src), false);
    });
  }

  getStyle() {
    const { transitionTime, timingFunction } = this.props;
    const { blur } = this.state;
    return {
      filter: `blur(${blur}px)`,
      transition: `filter ${transitionTime}ms ${timingFunction}`,
    };
  }

  render() {
    const { src } = this.state;
    const { render } = this.props;
    return render(src, this.getStyle());
  }
}

ProgressiveImage.defaultProps = {
  transitionTime: 500,
  timingFunction: 'ease',
  initialBlur: 10,
};

ProgressiveImage.propTypes = {
  preview: PropTypes.string,
  src: PropTypes.string,
  render: PropTypes.func,
  transitionTime: PropTypes.number,
  timingFunction: PropTypes.string,
  initialBlur: PropTypes.number,
};

export default ProgressiveImage;
