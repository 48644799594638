import React from 'react';
import Progress from 'react-topbar-progress-indicator';

Progress.config({
  barColors: {
    0: '#000000',
    0.5: '#000000',
    '1.0': '#000000',
  },
  shadowBlur: 10,
  barThickness: 6,
});

const TopBarProgress = () => {
  return <Progress></Progress>;
};

export default TopBarProgress;
