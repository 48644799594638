import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import MaterialLoader from '../../components/loader/materialLoader';

const PublicRoute = ({ component: Component, restricted, session, ...rest }) => {
  const { loading, logged } = session;

  const redirect = localStorage.getItem(process.env.REACT_APP_REDIRECT_URL);

  return loading ? (
    <div className="h-screen bg-white flex justify-center items-center">
      <MaterialLoader color="black" size="large" />
    </div>
  ) : (
    <Route
      {...rest}
      render={(props) => (logged && restricted ? <Redirect to={redirect || '/dashboard'} /> : <Component {...props} />)}
    ></Route>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  restricted: PropTypes.bool,
  session: PropTypes.object,
};

export default connect(mapStateToProps, null)(PublicRoute);
