import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Schema
import TeamSchema from './data/schema.json';

// Fields
import Input from '../../../fields/input';
import Textarea from '../../../fields/textarea';
import LoadingButton from '../../../button/loadingButton';

// Actions
import { notificationSuccess, notificationError } from '../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../services/firebase';

// API Services
import TeamAPI from '../../../../services/api/business/team';

// Strings
import { ui } from '../../../../strings/list.json';

// Form Validation
const teamSchema = yup.object().shape({
  name: yup.string().required(),
  designation: yup.string().required(),
  description: yup.string().required(),
});

const Form = ({ edit, member, notificationSuccess, notificationError }) => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      name: member ? member.name : '',
      designation: member ? member.designation : '',
      description: member ? member.description : '',
      facebook: member ? member.facebook || '' : '',
      twitter: member ? member.twitter || '' : '',
      instagram: member ? member.instagram || '' : '',
      linkedin: member ? member.linkedin || '' : '',
    },
    resolver: yupResolver(teamSchema),
  });

  const onSubmit = (data) => {
    setLoading(true);

    const team = {
      name: data.name,
      designation: data.designation,
      description: data.description,
      facebook: data.facebook || null,
      twitter: data.twitter || null,
      instagram: data.instagram || null,
      linkedin: data.linkedin || null,
    };

    if (edit) {
      team.id = member.id;

      updateMember(team);
    } else {
      createMember(team);
    }
  };

  // API Calls
  const createMember = async (data) => {
    try {
      const response = await TeamAPI.create(data);
      const { message } = response.data;

      setLoading(false);
      notificationSuccess(message);
      dispatch(process.env.REACT_APP_TEAM_LIST_REFRESH);
      dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          createMember(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const updateMember = async (data) => {
    try {
      const response = await TeamAPI.update(data);
      const { message } = response.data;

      setLoading(false);
      notificationSuccess(message);
      dispatch(process.env.REACT_APP_TEAM_LIST_REFRESH);
      dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          updateMember(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="pt-5 pb-3">
      <div className="absolute" style={{ right: '15px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE)}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
      <h1 className="text-xl text-gray-800 text-center mb-4 font-semibold">{edit ? 'Update Member' : 'Add Member'}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {TeamSchema.map((item, index) => {
          const error = ui.errors[errors[item.id]?.type] || errors[item.id]?.message;

          return (
            <div key={index} className="mb-4">
              <Controller
                name={item.id}
                control={control}
                render={({ value, onChange }) =>
                  item.field === 'input' ? (
                    <Input
                      id={item.id}
                      label={item.label}
                      placeholder={item.placeholder}
                      type={item.type}
                      value={value}
                      error={error}
                      required={item.required}
                      onChange={onChange}
                    ></Input>
                  ) : item.field === 'textarea' ? (
                    <Textarea
                      id={item.id}
                      label={item.label}
                      placeholder={item.placeholder}
                      rows={4}
                      value={value}
                      error={error}
                      required={item.required}
                      onChange={onChange}
                    ></Textarea>
                  ) : null
                }
              ></Controller>
            </div>
          );
        })}
        <LoadingButton label={edit ? 'Update' : 'Add'} fullWidth loading={loading} disabled={loading}></LoadingButton>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Form.propTypes = {
  edit: PropTypes.bool,
  member: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Form);
