import React, { useState } from 'react';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

// Styles
import './style.scss';

const GeneralDropdown = ({ value, options, onSelect }) => {
  const [open, setOpen] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className={classNames('general-dropdown relative', open && options.length > 0 && 'open')}>
        <div
          className="flex items-center justify-between cursor-pointer w-full"
          style={{ width: '110px', height: '35px' }}
          role="button"
          tabIndex="0"
          onKeyDown={() => {}}
          onClick={() => setOpen(!open)}
        >
          <p className="whitespace-no-wrap overflow-hidden" style={{ textOverflow: 'ellipsis' }}>
            {value}
          </p>
          <img src="/icons/down-arrow.svg" alt="Down Arrow" className="ml-2" style={{ height: '14px' }} />
        </div>
        <div className="dropdown-menu w-full rounded-br-lg absolute">
          <ul className="list-unstyled px-3">
            {options.map((item, index) => (
              <li
                key={index}
                className="dropdown-item relative"
                role="menuitem"
                tabIndex="0"
                onKeyDown={() => {}}
                onClick={() => {
                  onSelect(item);
                  setOpen(!open);
                }}
              >
                <p
                  className="whitespace-no-wrap overflow-hidden"
                  style={{ textOverflow: 'ellipsis', maxWidth: '100%' }}
                >
                  {item.name}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

GeneralDropdown.defaultProps = {};

GeneralDropdown.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func,
};

export default GeneralDropdown;
