/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import useBus, { dispatch } from 'use-bus';
import { useParams, useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { decode } from 'html-entities';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HttpStatusCodes from 'http-status-codes';
import classNames from 'classnames';
import Masonry from 'react-masonry-css';
import PropTypes from 'prop-types';

// Hooks
import useSwiperRef from '../../../../../hooks/useSwiperRef';

// Schema
import ArtSchema from '../../../../../data/business/artFurniture/art/schema.json';

// Fields
import Input from '../../../../../components/fields/input';
import Digit from '../../../../../components/fields/digit';
import Switch from '../../../../../components/fields/switch';
import Description from '../../../../../components/fields/description';

// Components
import ProgressiveImage from '../../../../../components/custom/progressiveImage';
import TopProgressBar from '../../../../../components/loader/topProgressBar';
import MaterialLoader from '../../../../../components/loader/materialLoader';
import LoadingButton from '../../../../../components/button/loadingButton';

// Actions
import { notificationSuccess, notificationError } from '../../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../../services/firebase';

// API Services
import ArtAPI from '../../../../../services/api/business/art';

// Strings
import { ui } from '../../../../../strings/list.json';

// Form Validation
const artSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  price: yup.string().required(),
  live: yup.boolean(),
});

const ArtDetails = ({ notificationSuccess, notificationError }) => {
  const { id } = useParams();
  const history = useHistory();
  const imageSwiperRef = useRef(null);
  const gallerySwiperRef = useRef(null);

  const [art, setArt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [imageNextEl, imageNextElRef] = useSwiperRef();
  const [imagePrevEl, imagePrevElRef] = useSwiperRef();
  const [galleryNextEl, galleryNextElRef] = useSwiperRef();
  const [galleryPrevEl, galleryPrevElRef] = useSwiperRef();

  const { control, handleSubmit, setValue, errors } = useForm({
    defaultValues: {
      name: '',
      description: '',
      warranty: '',
      eta: '',
      price: 0,
      live: false,
    },
    resolver: yupResolver(artSchema),
  });

  useEffect(() => {
    setDeleting(false);
    setUpdating(false);

    details();
  }, []);

  useBus(
    process.env.REACT_APP_ART_REFRESH,
    () => {
      details();
    },
    []
  );

  useBus(
    process.env.REACT_APP_ART_CROP_MODAL,
    ({ payload }) => {
      const { dataUrl } = payload;

      const data = new FormData();
      data.append('id', art.id);
      data.append('image', dataURLtoFile(dataUrl, 'Image'));

      thumbnailUpload(data);
    },
    [art]
  );

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      dispatch({
        type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
        payload: {
          layout: process.env.REACT_APP_IMAGE_UPLOAD_MODAL,
          data: {
            type: process.env.REACT_APP_ART_CROP_MODAL,
            id: null,
            index: 0,
            src: fileReader.result,
          },
        },
      });
    };

    if (file) {
      fileReader.readAsDataURL(file);
    }
  };

  const onSubmit = (data) => {
    const object = {
      id: art.id,
      name: data.name,
      description: data.description,
      warranty: data.warranty || null,
      eta: data.eta || null,
      price: Number(data.price),
      live: data.live,
    };

    update(object);
  };

  const dataURLtoFile = (dataUrl, filename) => {
    const array = dataUrl.split(',');
    const mime = array[0].match(/:(.*?);/)[1];
    const blobString = atob(array[1]);
    let length = blobString.length;
    const uInt8Array = new Uint8Array(length);

    while (length--) {
      uInt8Array[length] = blobString.charCodeAt(length);
    }

    return new File([uInt8Array], filename, { type: mime });
  };

  // API Calls
  const details = async (loader) => {
    if (loader) {
      setLoading(true);
    }

    try {
      const response = await ArtAPI.details(id);
      const result = response.data;
      setArt(result);
      setLoading(false);
      dispatch({
        type: process.env.REACT_APP_CHANGE_PAGE_TITLE,
        payload: {
          title: `Art | ${result.name}`,
        },
      });

      // Set Values
      setValue('name', result.name);
      setValue('description', decode(result.description));
      setValue('warranty', result.warranty || '');
      setValue('eta', result.eta || '');
      setValue('price', result.price);
      setValue('live', result.live);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          details();
        } else {
          notificationError(message);
        }
      }
    }
  };

  const thumbnailUpload = async (data) => {
    setDeleting(true);

    try {
      const response = await ArtAPI.thumbnailUpload(data);
      const { message } = response.data;

      setDeleting(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setDeleting(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          thumbnailUpload(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const variantDelete = async (id, variantID) => {
    setDeleting(true);

    try {
      const response = await ArtAPI.variantDelete({
        id,
        variantID,
      });
      const { message } = response.data;

      setDeleting(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setDeleting(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          variantDelete(id, variantID);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const dimensionDelete = async (id, dimensionID) => {
    setDeleting(true);

    try {
      const response = await ArtAPI.dimensionDelete({
        id,
        dimensionID,
      });
      const { message } = response.data;

      setDeleting(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setDeleting(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          dimensionDelete(id, dimensionID);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const imageDelete = async (data) => {
    setDeleting(true);

    try {
      const response = await ArtAPI.imageDelete(data);
      const { message } = response.data;

      setDeleting(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setDeleting(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          imageDelete(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const update = async (data) => {
    setUpdating(true);

    try {
      const response = await ArtAPI.update(data);
      const { message } = response.data;

      setUpdating(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setUpdating(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          update(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="art-details pt-32">
      {deleting && <TopProgressBar />}
      {loading ? (
        <div className="absolute top-0 left-0 flex items-center w-full h-screen">
          <MaterialLoader color="black" size="large" />
        </div>
      ) : (
        <div className="container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 mb-5 ml-4 md:ml-8 cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            onClick={() => history.goBack()}
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path>
          </svg>
          <div className="row">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-12 sm:col-span-6">
                <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                  {ArtSchema.map((item, index) => {
                    const error = ui.errors[errors[item.id]?.type] || errors[item.id]?.message;

                    return (
                      <div key={index} className="mb-4">
                        <Controller
                          name={item.id}
                          control={control}
                          render={({ value, onChange }) => {
                            return item.field === 'input' ? (
                              <Input
                                id={item.id}
                                label={item.label}
                                type={item.type}
                                value={value}
                                error={error}
                                required={item.required}
                                onChange={onChange}
                              ></Input>
                            ) : item.field === 'description' ? (
                              <Description
                                id={item.id}
                                label={item.label}
                                value={value}
                                error={error}
                                required={item.required}
                                onChange={onChange}
                              ></Description>
                            ) : item.field === 'number' ? (
                              <Digit
                                id={item.id}
                                label={item.label}
                                value={value}
                                mask={item.mask}
                                error={error}
                                onChange={onChange}
                                required={item.required}
                              ></Digit>
                            ) : item.field === 'switch' ? (
                              <Switch label={item.label} name={item.id} value={value} onChange={onChange}></Switch>
                            ) : null;
                          }}
                        ></Controller>
                      </div>
                    );
                  })}
                  <LoadingButton label={'Update'} fullWidth loading={updating} disabled={updating}></LoadingButton>
                </form>
              </div>
              <div className="col-span-12 sm:col-span-6">
                <div className="art-thumbnail pb-3">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-base font-bold">Thumbnail</span>
                    <input accept="image/*" hidden id="image" type="file" onChange={handleImageChange} />
                    <label htmlFor="image" type="button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        onClick={() => {}}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </label>
                  </div>
                  {art?.thumbnail?.url && (
                    <ProgressiveImage
                      preview={art?.thumbnail?.thumbnailURL}
                      src={art?.thumbnail?.url}
                      render={(src, style) => (
                        <img className={classNames('w-40 h-40')} src={src} style={style} alt={''} />
                      )}
                    ></ProgressiveImage>
                  )}
                </div>
                <div className="art-dimension">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-base font-bold">Dimensions</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      onClick={() =>
                        dispatch({
                          type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                          payload: {
                            type: process.env.REACT_APP_ART_MODAL,
                            layout: process.env.REACT_APP_ART_DIMENSION_MODAL,
                            data: {
                              edit: false,
                              id: art.id,
                            },
                          },
                        })
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <Masonry
                    breakpointCols={{
                      default: 3,
                      1024: 3,
                      768: 2,
                      425: 1,
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-grid-column"
                  >
                    {(art?.dimensions).map((item, index) => (
                      <div key={index} className={classNames('border-2 border-solid border-transparent')}>
                        <div
                          className="p-3 text-sm cursor-pointer shadow-md rounded-md border border-solid border-gray-200"
                          style={{ backgroundColor: '#ffffff' }}
                        >
                          <p>
                            Name: <span className="font-semibold">{item.name}</span>
                          </p>
                          <p>
                            Value: <span className="font-semibold">{item.value}</span>
                          </p>
                          <div className="flex justify-start" style={{ marginTop: '10px', fontSize: '13px' }}>
                            <span
                              className="underline cursor-pointer"
                              role="presentation"
                              onClick={() =>
                                dispatch({
                                  type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                                  payload: {
                                    type: process.env.REACT_APP_ART_MODAL,
                                    layout: process.env.REACT_APP_ART_DIMENSION_MODAL,
                                    data: {
                                      edit: true,
                                      id: art.id,
                                      dimension: item,
                                    },
                                  },
                                })
                              }
                            >
                              Edit
                            </span>
                            <span
                              className="underline cursor-pointer ml-2"
                              role="presentation"
                              onClick={() => dimensionDelete(art.id, item.id)}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </div>
                <div className="art-variant">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-base font-bold">Variant</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      onClick={() =>
                        dispatch({
                          type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                          payload: {
                            type: process.env.REACT_APP_ART_MODAL,
                            layout: process.env.REACT_APP_ART_VARIANT_MODAL,
                            data: {
                              edit: false,
                              id: art.id,
                            },
                          },
                        })
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <Masonry
                    breakpointCols={{
                      default: 3,
                      1024: 3,
                      768: 2,
                      425: 1,
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-grid-column"
                  >
                    {(art?.variant).map((item, index) => (
                      <div key={index} className={classNames('border-2 border-solid border-transparent')}>
                        <div
                          className="p-3 text-sm cursor-pointer shadow-md rounded-md border border-solid border-gray-200"
                          style={{ backgroundColor: '#ffffff' }}
                        >
                          <p>
                            Name: <span className="font-semibold">{item.name}</span>
                          </p>
                          <div className="art-variant-sizes my-2">
                            <h1 className="font-bold">Sizes</h1>
                            {(item?.sizes).map((size, index) => (
                              <div
                                key={index}
                                className={classNames(
                                  'p-2 shadow-md rounded-md border border-solid border-gray-200',
                                  index < item?.sizes.length - 1 && 'mb-2'
                                )}
                                style={{ fontSize: '12px' }}
                              >
                                <p>
                                  Name: <span className="font-semibold">{size.name}</span>
                                </p>
                                <p>
                                  Price: <span className="font-semibold">{size.price}</span>
                                </p>
                                <p>
                                  Weight (KG): <span className="font-semibold">{size.weight}</span>
                                </p>
                              </div>
                            ))}
                          </div>
                          <p>
                            Original: <span className="font-semibold">{item.original ? 'Yes' : 'No'}</span>
                          </p>
                          <div className="flex justify-start" style={{ marginTop: '10px', fontSize: '13px' }}>
                            <span
                              className="underline cursor-pointer"
                              role="presentation"
                              onClick={() =>
                                dispatch({
                                  type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                                  payload: {
                                    type: process.env.REACT_APP_ART_MODAL,
                                    layout: process.env.REACT_APP_ART_VARIANT_MODAL,
                                    data: {
                                      edit: true,
                                      id: art.id,
                                      variant: item,
                                    },
                                  },
                                })
                              }
                            >
                              Edit
                            </span>
                            <span
                              className="underline cursor-pointer ml-2"
                              role="presentation"
                              onClick={() => variantDelete(art.id, item.id)}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </div>
                <div className="art-images pb-3">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-bold">Images</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      onClick={() =>
                        dispatch({
                          type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                          payload: {
                            type: process.env.REACT_APP_ART_MODAL,
                            layout: process.env.REACT_APP_ART_IMAGES_MODAL,
                            data: {
                              id: art.id,
                              gallery: 0,
                            },
                          },
                        })
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <Swiper
                    ref={imageSwiperRef}
                    style={{ '--swiper-navigation-color': '#ffffff', '--swiper-pagination-color': '#ffffff' }}
                    onSwiper={(value) => {}}
                    spaceBetween={10}
                    slidesPerView={3}
                    navigation={{
                      imagePrevEl,
                      imageNextEl,
                    }}
                    freeMode={true}
                    centeredSlidesBounds={false}
                    watchSlidesVisibility={true}
                    watchSlidesProgress={true}
                    observer={true}
                    observeParents={true}
                    className="relative"
                  >
                    {art?.images.map((item, index) => (
                      <SwiperSlide key={index} className="cursor-pointer">
                        <Fragment>
                          <img src={item.thumbnail} alt={art?.name || ''} className="w-full" />
                          <p
                            className="text-sm text-center underline cursor-pointer mt-2"
                            role="presentation"
                            onClick={() => {
                              const data = {
                                id: art.id,
                                gallery: 0,
                                fileId: item.fileId,
                              };

                              imageDelete(data);
                            }}
                          >
                            Delete
                          </p>
                        </Fragment>
                      </SwiperSlide>
                    ))}
                    {art?.images.length > 3 && (
                      <Fragment>
                        <div className="h-full flex items-center absolute top-0 left-0 z-10 px-1">
                          <button
                            ref={imagePrevElRef}
                            className="bg-white flex items-center justify-center rounded-full shadow-md w-6 h-6 cursor-pointer"
                            onClick={() => imageSwiperRef.current.swiper.slidePrev()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16l-4-4m0 0l4-4m-4 4h18"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        <div className="h-full flex items-center absolute top-0 right-0 z-10 px-1">
                          <button
                            ref={imageNextElRef}
                            className="bg-white flex items-center justify-center rounded-full shadow-md w-6 h-6 cursor-pointer"
                            onClick={() => imageSwiperRef.current.swiper.slideNext()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </Fragment>
                    )}
                  </Swiper>
                </div>
                <div className="art-gallery pb-3">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-bold">Gallery</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      onClick={() =>
                        dispatch({
                          type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                          payload: {
                            type: process.env.REACT_APP_ART_MODAL,
                            layout: process.env.REACT_APP_ART_IMAGES_MODAL,
                            data: {
                              id: art.id,
                              gallery: 1,
                            },
                          },
                        })
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <Swiper
                    ref={gallerySwiperRef}
                    style={{ '--swiper-navigation-color': '#ffffff', '--swiper-pagination-color': '#ffffff' }}
                    onSwiper={(value) => {}}
                    spaceBetween={10}
                    slidesPerView={3}
                    navigation={{
                      galleryPrevEl,
                      galleryNextEl,
                    }}
                    freeMode={true}
                    centeredSlidesBounds={false}
                    watchSlidesVisibility={true}
                    watchSlidesProgress={true}
                    observer={true}
                    observeParents={true}
                    className="relative"
                  >
                    {art?.gallery.map((item, index) => (
                      <SwiperSlide key={index} className="cursor-pointer">
                        <Fragment>
                          <img src={item.thumbnail} alt={art?.name || ''} className="w-full" />
                          <p
                            className="text-sm text-center underline cursor-pointer mt-2"
                            role="presentation"
                            onClick={() => {
                              const data = {
                                id: art.id,
                                gallery: 1,
                                fileId: item.fileId,
                              };

                              imageDelete(data);
                            }}
                          >
                            Delete
                          </p>
                        </Fragment>
                      </SwiperSlide>
                    ))}
                    {art?.gallery.length > 3 && (
                      <Fragment>
                        <div className="h-full flex items-center absolute top-0 left-0 z-10 px-1">
                          <button
                            ref={galleryPrevElRef}
                            className="bg-white flex items-center justify-center rounded-full shadow-md w-6 h-6 cursor-pointer"
                            onClick={() => gallerySwiperRef.current.swiper.slidePrev()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16l-4-4m0 0l4-4m-4 4h18"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        <div className="h-full flex items-center absolute top-0 right-0 z-10 px-1">
                          <button
                            ref={galleryNextElRef}
                            className="bg-white flex items-center justify-center rounded-full shadow-md w-6 h-6 cursor-pointer"
                            onClick={() => gallerySwiperRef.current.swiper.slideNext()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </Fragment>
                    )}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

ArtDetails.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtDetails);
