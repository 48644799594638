import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Dropdown from '../../../components/custom/dropdown/general';

// Actions
import { notificationSuccess, notificationError } from '../../../redux/root/actions';

// Sections
import Architecture from './architecture';
import Interiors from './interiors';

// Options
const options = [
  {
    id: 'architecture',
    name: 'Architecture',
  },
  {
    id: 'interiors',
    name: 'Interiors',
  },
];

const CaseStudy = () => {
  const [page, setPage] = useState(options[0]);

  return (
    <div className="case-study pt-32">
      <div className="container">
        <div className="row">
          <div className="w-full">
            <div className="h-12 flex items-center justify-between">
              <Dropdown
                value={page?.name}
                options={options}
                onSelect={(value) => {
                  setPage(value);
                }}
              />
            </div>
          </div>
        </div>
        {page && (
          <div style={{ marginTop: '10px' }}>{page?.id === 'architecture' ? <Architecture /> : <Interiors />}</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

CaseStudy.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudy);
