import React, { Fragment, useRef, useState, useCallback } from 'react';
import { dispatch } from 'use-bus';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';

const ImageCrop = ({ type, id, index, src }) => {
  const imageRef = useRef(null);

  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    aspect: 1 / 1,
  });

  const onLoad = useCallback((image) => {
    imageRef.current = image;
  }, []);

  const getCroppedImage = (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    const context = canvas.getContext('2d');

    context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    context.imageSmoothingQuality = 'high';

    context.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            resolve(reader.result);
          };
        },
        'image/png',
        1
      );
    });
  };

  return (
    <Fragment>
      <div className="absolute" style={{ right: '15px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE)}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
      <h1 className="text-xl text-gray-800 text-center mb-4 font-semibold">Image Crop</h1>
      <ReactCrop className="max-h-full" src={src} crop={crop} onImageLoaded={onLoad} onChange={setCrop} />
      <button
        className="bg-black text-white font-semibold shadow-md rounded-sm border-0 px-3 py-2 mt-3 cursor-pointer"
        onClick={async () => {
          dispatch({
            type: type,
            payload: {
              id,
              index,
              dataUrl: await getCroppedImage(imageRef.current, crop),
            },
          });
          dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
        }}
      >
        Upload
      </button>
    </Fragment>
  );
};

ImageCrop.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  index: PropTypes.number,
  src: PropTypes.string,
};

export default ImageCrop;
