/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from 'react';
import { dispatch } from 'use-bus';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const ScrollToTop = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(process.env.REACT_APP_SCROLL_TO_TOP);
    });

    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
};

ScrollToTop.propTypes = {
  history: PropTypes.object,
  children: PropTypes.object,
};

export default withRouter(ScrollToTop);
