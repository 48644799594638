import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Decimal = ({ id, label, value, placeholder, error, onChange, required }) => {
  const isDecimal = (value) => {
    return value.match(/^(\d+\.?\d*|\.\d+)$/) != null;
  };

  const handleOnChange = (event) => {
    const value = event.target.value;

    const currentValue = value.split('').reduce((string, char) => {
      const temp = string + char;
      return isDecimal(temp) ? temp : string;
    }, '');

    onChange(currentValue);
  };

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-1">
        <label htmlFor={id} className="block text-sm font-bold text-gray-800">
          {label}
          {required && (
            <span className="text-red-600" style={{ marginLeft: '2px' }}>
              *
            </span>
          )}
        </label>
        <p className="text-red-600 text-xs text-right font-semibold">{error}</p>
      </div>
      <input
        type="tel"
        id={id}
        value={value}
        placeholder={placeholder}
        className={classNames(
          'block p-3 rounded border bg-gray-200 placeholder-gray-600 cursor-pointer flex-grow',
          error ? 'border-solid border-red-600' : 'border-transparent'
        )}
        onChange={handleOnChange}
        style={{ width: '-webkit-fill-available' }}
      />
    </Fragment>
  );
};

Decimal.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default Decimal;
