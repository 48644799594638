/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Schema
import OthersSchema from '../../../../data/business/others/schema.json';

// Fields
import Input from '../../../../components/fields/input';
import Digit from '../../../../components/fields/digit';
import Switch from '../../../../components/fields/switch';
import Dropdown from '../../../../components/fields/dropdown';
import Description from '../../../../components/fields/description';
import LoadingButton from '../../../../components/button/loadingButton';

// Actions
import { notificationSuccess, notificationError } from '../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../services/firebase';

// API Services
import CategoriesAPI from '../../../../services/api/master/paint/categories';
import TypesAPI from '../../../../services/api/master/paint/types';
import PaintsAPI from '../../../../services/api/business/vedicPaint';

// Strings
import { ui } from '../../../../strings/list.json';

// Form Validation
const othersSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  price: yup.string().required(),
  category: yup.object().required(),
  type: yup.object().required(),
  live: yup.boolean(),
});

const OthersCreate = ({ notificationSuccess, notificationError }) => {
  const history = useHistory();

  const [categoriesList, setCategories] = useState([]);
  const [typesList, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setValue, errors } = useForm({
    defaultValues: {
      name: '',
      description: '',
      warranty: '',
      eta: '',
      price: 0,
      category: null,
      type: null,
      live: false,
    },
    resolver: yupResolver(othersSchema),
  });

  useEffect(() => {
    categories();
  }, []);

  const onSubmit = (data) => {
    setLoading(true);

    const paint = {
      name: data.name,
      description: data.description,
      warranty: data.warranty || null,
      eta: data.eta || null,
      price: Number(data.price),
      category: data.category.id,
      type: data.type.id,
      live: data.live,
    };

    create(paint);
  };

  // API Calls
  const categories = async () => {
    try {
      const response = await CategoriesAPI.list();
      const result = response.data;
      setCategories(result);
      setValue('category', result[0]);
      types();
    } catch (error) {
      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          categories();
        } else {
          notificationError(message);
        }
      }
    }
  };

  const types = async () => {
    try {
      const response = await TypesAPI.list();
      const result = response.data;
      setTypes(result);
      setValue('type', result[0]);
    } catch (error) {
      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          types();
        } else {
          notificationError(message);
        }
      }
    }
  };

  const create = async (data) => {
    setLoading(true);

    try {
      const response = await PaintsAPI.create(data);
      const { message, id } = response.data;

      setLoading(false);
      notificationSuccess(message);
      history.push(`/others/details/${id}`);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          create(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="others-create pt-32">
      <div className="container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 mb-5 ml-4 md:ml-8 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => history.goBack()}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path>
        </svg>
        <div className="row">
          <div className="grid grid-cols-12 gap-6 w-full">
            <div className="col-span-12 sm:col-span-6">
              <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                {OthersSchema.map((item, index) => {
                  const error = ui.errors[errors[item.id]?.type] || errors[item.id]?.message;

                  return (
                    <div key={index} className="mb-4">
                      <Controller
                        name={item.id}
                        control={control}
                        render={({ value, onChange }) => {
                          return item.field === 'input' ? (
                            <Input
                              id={item.id}
                              label={item.label}
                              type={item.type}
                              value={value}
                              error={error}
                              required={item.required}
                              onChange={onChange}
                            ></Input>
                          ) : item.field === 'description' ? (
                            <Description
                              id={item.id}
                              label={item.label}
                              value={value}
                              error={error}
                              required={item.required}
                              onChange={onChange}
                            ></Description>
                          ) : item.field === 'dropdown' ? (
                            <Dropdown
                              label={item.label}
                              name={item.id}
                              options={item.id === 'category' ? categoriesList : typesList}
                              value={value}
                              required={item.required}
                              onChange={onChange}
                            ></Dropdown>
                          ) : item.field === 'number' ? (
                            <Digit
                              id={item.id}
                              label={item.label}
                              value={value}
                              mask={item.mask}
                              error={error}
                              onChange={onChange}
                              required={item.required}
                            ></Digit>
                          ) : item.field === 'switch' ? (
                            <Switch label={item.label} name={item.id} value={value} onChange={onChange}></Switch>
                          ) : null;
                        }}
                      ></Controller>
                    </div>
                  );
                })}
                <LoadingButton label={'Create'} fullWidth loading={loading} disabled={loading}></LoadingButton>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

OthersCreate.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(OthersCreate);
