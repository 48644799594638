import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import PropTypes from 'prop-types';

// Schema
import LoginSchema from './data/schema.json';

// Fields
import Input from '../../../components/fields/input';
import Password from '../../../components/fields/password';
import LoadingButton from '../../../components/button/loadingButton';

// Strings
import { ui, responses, firebase } from '../../../strings/list.json';

// Actions
import { notificationSuccess, notificationError } from '../../../redux/root/actions';

// Firebase Services
import { signIn } from '../../../services/firebase/auth';

// Form Validation
const loginSchema = yup.object().shape({
  email: yup.string().email(ui.errors.invalid_email).required(),
  password: yup.string().required(),
});

const Login = ({ notificationSuccess, notificationError }) => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const { email, password } = data;
      await signIn(email, password);

      setLoading(false);
      notificationSuccess(responses.user.logged_in);
    } catch (error) {
      const { code } = error;

      setLoading(false);
      notificationError(firebase.errors[code]);
    }
  };

  return (
    <div className="login">
      <div className="mx-auto flex overflow-y-auto" style={{ minHeight: 'calc(100vh - 0rem)' }}>
        <div className="max-w-sm w-full mx-auto my-auto">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden border border-solid border-gray-200 py-4">
            <div className="pb-6 pt-6 pr-8 pl-8">
              <h1 className="text-xl text-gray-800 text-center mb-4 font-semibold">Sign In</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                {LoginSchema.map((item, index) => {
                  const error = ui.errors[errors[item.id]?.type] || errors[item.id]?.message || null;

                  return (
                    <div key={index} className="mb-4">
                      <Controller
                        name={item.id}
                        control={control}
                        render={({ value, onChange }) =>
                          item.general ? (
                            <Input
                              id={item.id}
                              label={item.label}
                              placeholder={item.placeholder}
                              value={value}
                              type={item.type}
                              error={error}
                              onChange={onChange}
                            ></Input>
                          ) : item.password ? (
                            <Password label={item.label} value={value} error={error} onChange={onChange}></Password>
                          ) : null
                        }
                      ></Controller>
                    </div>
                  );
                })}
                <LoadingButton label={'Sign In'} fullWidth={true} loading={loading} disabled={loading} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Login.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
