import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Schema
import ArtSchema from '../../../../../data/business/artFurniture/art/schema.json';

// Fields
import Input from '../../../../../components/fields/input';
import Digit from '../../../../../components/fields/digit';
import Switch from '../../../../../components/fields/switch';
import Description from '../../../../../components/fields/description';
import LoadingButton from '../../../../../components/button/loadingButton';

// Actions
import { notificationSuccess, notificationError } from '../../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../../services/firebase';

// API Services
import ArtAPI from '../../../../../services/api/business/art';

// Strings
import { ui } from '../../../../../strings/list.json';

// Form Validation
const artSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  price: yup.string().required(),
  live: yup.boolean(),
});

const ArtCreate = ({ notificationSuccess, notificationError }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      name: '',
      description: '',
      warranty: '',
      eta: '',
      price: 0,
      live: false,
    },
    resolver: yupResolver(artSchema),
  });

  const onSubmit = (data) => {
    setLoading(true);

    const art = {
      name: data.name,
      description: data.description,
      warranty: data.warranty || null,
      eta: data.eta || null,
      price: Number(data.price),
      live: data.live,
    };

    create(art);
  };

  // API Calls
  const create = async (data) => {
    setLoading(true);

    try {
      const response = await ArtAPI.create(data);
      const { message, id } = response.data;

      setLoading(false);
      notificationSuccess(message);
      history.push(`/art-furniture/art/details/${id}`);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          create(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="art-create pt-32">
      <div className="container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 mb-5 ml-4 md:ml-8 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => history.goBack()}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path>
        </svg>
        <div className="row">
          <div className="grid grid-cols-12 gap-6 w-full">
            <div className="col-span-12 sm:col-span-6">
              <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                {ArtSchema.map((item, index) => {
                  const error = ui.errors[errors[item.id]?.type] || errors[item.id]?.message;

                  return (
                    <div key={index} className="mb-4">
                      <Controller
                        name={item.id}
                        control={control}
                        render={({ value, onChange }) => {
                          return item.field === 'input' ? (
                            <Input
                              id={item.id}
                              label={item.label}
                              type={item.type}
                              value={value}
                              error={error}
                              required={item.required}
                              onChange={onChange}
                            ></Input>
                          ) : item.field === 'description' ? (
                            <Description
                              id={item.id}
                              label={item.label}
                              value={value}
                              error={error}
                              required={item.required}
                              onChange={onChange}
                            ></Description>
                          ) : item.field === 'number' ? (
                            <Digit
                              id={item.id}
                              label={item.label}
                              value={value}
                              mask={item.mask}
                              error={error}
                              onChange={onChange}
                              required={item.required}
                            ></Digit>
                          ) : item.field === 'switch' ? (
                            <Switch label={item.label} name={item.id} value={value} onChange={onChange}></Switch>
                          ) : null;
                        }}
                      ></Controller>
                    </div>
                  );
                })}
                <LoadingButton label={'Create'} fullWidth loading={loading} disabled={loading}></LoadingButton>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

ArtCreate.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtCreate);
