import api from '../../axios';

export default {
  paid: (paid, allClear) => {
    return api({
      url: `/business/order/admin/paid`,
      method: `GET`,
      params: { paid, allClear },
    });
  },
  tba: () => {
    return api({
      url: `/business/order/admin/tba`,
      method: `GET`,
    });
  },
  details: (id) => {
    return api({
      url: `/business/order/admin/details/${id}`,
      method: `GET`,
    });
  },
  search: (term) => {
    return api({
      url: `/business/order/search`,
      method: `GET`,
      params: { term },
    });
  },
  update: (data) => {
    return api({
      url: `/business/order/update`,
      method: `POST`,
      data,
    });
  },
  downloadInvoice: (id) => {
    return api({
      url: `/business/order/download/invoice`,
      method: `POST`,
      data: {
        id,
      },
      responseType: 'blob',
    });
  },
};
