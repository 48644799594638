// Firebase Client
import { FirebaseAuth } from './client';

const Auth = new FirebaseAuth();

// Session Persistance
Auth.setPersistence(FirebaseAuth.Auth.Persistence.LOCAL);

// SignIn (Email, Password)
export const signIn = (email, password) => Auth.signInWithEmailAndPassword(email, password);

// Send Password Email
export const sendPasswordResetEmail = (email) => Auth.sendPasswordResetEmail(email);

// Auth State Changed
export const onAuthStateChanged = (user) => Auth.onAuthStateChanged(user);

// ID Token Changed
export const onIdTokenChanged = (user) => Auth.onIdTokenChanged(user);

// Sign Out
export const signOut = () => Auth.signOut();

// Current User
export const currentUser = () => Auth.currentUser;
