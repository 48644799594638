import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

// Data
import options from './data/options.json';

// Firebase Services
import { signOut } from '../../../../services/firebase';

// Styles
import './style.scss';

const ProfileDropdown = ({ children, size }) => {
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const onSelect = async (link, logout) => {
    if (logout) {
      localStorage.clear();
      await signOut();
    } else {
      setOpen(false);
      history.push(link);
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className="flex flex-col relative">
        <div
          className="avatar"
          role="button"
          tabIndex="0"
          onKeyDown={() => {}}
          onClick={() => {
            const tabletMenuState = JSON.parse(localStorage.getItem(process.env.REACT_APP_TABLET_MENU_STATE));

            if (!tabletMenuState) {
              setOpen((open) => !open);
            }
          }}
        >
          {children}
        </div>
        <div className={classNames('menu-dropdown absolute bg-white rounded-br-lg p-4', open && 'open', size)}>
          <div className="menu-content">
            <ul className="list-none m-0 p-0">
              {options.map(
                (item, index) =>
                  !item.disabled && (
                    <li
                      key={index}
                      className="cursor-pointer flex items-center text-left relative"
                      role="menuitem"
                      tabIndex="0"
                      onKeyDown={() => {}}
                      onClick={() => onSelect(item.link, item.logout)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 mr-2 align-bottom"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={item.icon}></path>
                      </svg>
                      <span className="text-black no-underline absolute" style={{ fontSize: '14px', marginTop: '3px' }}>
                        {item.title}
                      </span>
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

ProfileDropdown.defaultProps = {
  size: 'large',
};

ProfileDropdown.propTypes = {
  children: PropTypes.object,
  size: PropTypes.oneOf(['small', 'large']),
};

export default ProfileDropdown;
