import React, { useEffect } from 'react';

// App Layout
import AppLayout from '../layouts/appLayout';

// Router
import Main from '../router/main';

const App = () => {
  useEffect(() => {}, []);

  return (
    <AppLayout>
      <Main />
    </AppLayout>
  );
};

export default App;
