import { SESSION_ACTIVE, SESSION_INACTIVE } from './types';

const initialState = {
  logged: false,
  idToken: null,
  user: {
    name: '',
  },
  loading: true,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SESSION_ACTIVE:
      state = {
        ...state,
        logged: true,
        idToken: action.payload.idToken,
        user: action.payload.user,
        loading: false,
      };
      break;
    case SESSION_INACTIVE:
      state = {
        ...state,
        logged: false,
        idToken: '',
        user: {},
        loading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default sessionReducer;
