import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import { useMediaQuery } from 'react-responsive';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HttpStatusCodes from 'http-status-codes';
import moment from 'moment';
import PropTypes from 'prop-types';

// Schema
import TimelineSchema from './data/schema.json';

// Fields
import Input from '../../../fields/input';
import Textarea from '../../../fields/textarea';
import DatePicker from '../../../fields/datePicker';
import LoadingButton from '../../../button/loadingButton';

// Actions
import { notificationSuccess, notificationError } from '../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../services/firebase';

// API Services
import CaseStudyAPI from '../../../../services/api/business/caseStudy';

// Strings
import { ui } from '../../../../strings/list.json';

// Form Validation
const timelineSchema = yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
});

const Timeline = ({ edit, id, timeline, notificationSuccess, notificationError }) => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      title: timeline ? timeline.title : '',
      description: timeline ? timeline.description : '',
      date: timeline ? moment(timeline.date, 'DD/MM/YYYY') : moment(),
    },
    resolver: yupResolver(timelineSchema),
  });

  const isTabletOrMobile = useMediaQuery({ maxWidth: 992 });

  const onSubmit = (data) => {
    setLoading(true);

    const object = {
      title: data.title,
      description: data.description,
      date: data.date,
    };

    if (edit) {
      object.id = timeline.id;

      updateTimeline({
        _id: id,
        timeline: object,
      });
    } else {
      createTimeline({
        _id: id,
        timeline: object,
      });
    }
  };

  // API Calls
  const createTimeline = async (data) => {
    try {
      const response = await CaseStudyAPI.timelineCreate(data);
      const { message } = response.data;

      setLoading(false);
      notificationSuccess(message);
      dispatch(process.env.REACT_APP_CASE_STUDY_REFRESH);
      dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          createTimeline(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const updateTimeline = async (data) => {
    try {
      const response = await CaseStudyAPI.timelineUpdate(data);
      const { message } = response.data;

      setLoading(false);
      notificationSuccess(message);
      dispatch(process.env.REACT_APP_CASE_STUDY_REFRESH);
      dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          updateTimeline(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="pt-5 pb-3 relative">
      <div className="absolute" style={{ right: '15px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE)}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
      <h1 className="text-xl text-gray-800 text-center mb-4 font-semibold">Timeline</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {TimelineSchema.map((item, index) => {
          const error = ui.errors[errors[item.id]?.type] || errors[item.id]?.message;

          return (
            <div key={index} className="mb-4">
              <Controller
                name={item.id}
                control={control}
                render={({ value, onChange }) =>
                  item.field === 'input' ? (
                    <Input
                      id={item.id}
                      label={item.label}
                      placeholder={item.placeholder}
                      type={item.type}
                      value={value}
                      error={error}
                      required={item.required}
                      onChange={onChange}
                    ></Input>
                  ) : item.field === 'textarea' ? (
                    <Textarea
                      id={item.id}
                      label={item.label}
                      placeholder={item.placeholder}
                      rows={4}
                      value={value}
                      error={error}
                      required={item.required}
                      onChange={onChange}
                    ></Textarea>
                  ) : item.field === 'date' ? (
                    <DatePicker
                      label={item.label}
                      value={value}
                      error={error}
                      isPortal={isTabletOrMobile}
                      onChange={onChange}
                    ></DatePicker>
                  ) : null
                }
              ></Controller>
            </div>
          );
        })}
        <LoadingButton label={edit ? 'Update' : 'Add'} fullWidth loading={loading} disabled={loading}></LoadingButton>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Timeline.propTypes = {
  edit: PropTypes.bool,
  id: PropTypes.string,
  timeline: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Timeline);
