/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HttpStatusCodes from 'http-status-codes';
import moment from 'moment';
import PropTypes from 'prop-types';

// Schema
import CaseStudySchema from '../../../../../data/business/caseStudy/schema.json';

// Fields
import Input from '../../../../../components/fields/input';
import Digit from '../../../../../components/fields/digit';
import Switch from '../../../../../components/fields/switch';
import Dropdown from '../../../../../components/fields/dropdown';
import Textarea from '../../../../../components/fields/textarea';
import DatePicker from '../../../../../components/fields/datePicker';
import LoadingButton from '../../../../../components/button/loadingButton';

// Actions
import { notificationSuccess, notificationError } from '../../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../../services/firebase';

// API Services
import CaseStudyAPI from '../../../../../services/api/business/caseStudy';

// Strings
import { ui } from '../../../../../strings/list.json';

// Options
const categoriesList = [
  {
    id: 'architecture',
    name: 'Architecture',
  },
  {
    id: 'interiors',
    name: 'Interiors',
  },
];

const typesList = [
  {
    id: 'commercial',
    name: 'Commercial',
  },
  {
    id: 'residential',
    name: 'Residential',
  },
  {
    id: 'factories',
    name: 'Factories',
  },
  {
    id: 'institutions',
    name: 'Institutions',
  },
  {
    id: 'masterPlanning',
    name: 'Master Planning',
  },
  {
    id: 'hospitality',
    name: 'Hospitality',
  },
  {
    id: 'healthcare',
    name: 'Healthcare',
  },
];

const status = [
  {
    id: 'in-progress',
    name: 'In Progress',
  },
  {
    id: 'complete',
    name: 'Complete',
  },
];

// Form Validation
const caseStudySchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  concept: yup.string(),
  category: yup.object().nullable().required(),
  type: yup.object().nullable().required(),
  status: yup.object().nullable().required(),
  place: yup.string().required(),
  area: yup.number().required(),
  active: yup.boolean(),
});

const CaseStudyCreate = ({ notificationSuccess, notificationError }) => {
  const history = useHistory();

  const [types, setTypes] = useState(typesList);
  const [loading, setLoading] = useState(false);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 992 });

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      name: '',
      description: '',
      concept: '',
      date: moment(),
      category: categoriesList[0],
      type: typesList[0],
      status: status[0],
      place: '',
      area: 0,
      active: false,
    },
    resolver: yupResolver(caseStudySchema),
  });

  const onSubmit = (data) => {
    setLoading(true);

    const caseStudy = {
      name: data.name,
      description: data.description,
      concept: data.concept,
      date: data.date,
      category: data.category.id,
      type: data.type.id,
      status: data.status.name,
      place: data.place,
      area: Number(data.area),
      active: data.active,
    };

    create(caseStudy);
  };

  // API Calls
  const create = async (data) => {
    setLoading(true);

    try {
      const response = await CaseStudyAPI.create(data);
      const { message, id } = response.data;

      setLoading(false);
      notificationSuccess(message);
      history.push(`/case-study/details/${id}`);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          create(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="case-study-create pt-32">
      <div className="container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 mb-5 ml-4 md:ml-8 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => history.goBack()}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path>
        </svg>
        <div className="row">
          <div className="grid grid-cols-12 gap-6 w-full">
            <div className="col-span-12 sm:col-span-6">
              <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                {CaseStudySchema.map((item, index) => {
                  const error = ui.errors[errors[item.id]?.type] || errors[item.id]?.message;

                  return (
                    <div key={index} className="mb-4">
                      <Controller
                        name={item.id}
                        control={control}
                        render={({ value, onChange }) => {
                          return item.field === 'input' ? (
                            <Input
                              id={item.id}
                              label={item.label}
                              type={item.type}
                              value={value}
                              error={error}
                              required={item.required}
                              onChange={onChange}
                            ></Input>
                          ) : item.field === 'textarea' ? (
                            <Textarea
                              id={item.id}
                              label={item.label}
                              placeholder={item.placeholder}
                              rows={4}
                              value={value}
                              error={error}
                              required={item.required}
                              onChange={onChange}
                            ></Textarea>
                          ) : item.field === 'dropdown' ? (
                            <Dropdown
                              label={item.label}
                              name={item.id}
                              options={item.id === 'category' ? categoriesList : item.id === 'type' ? types : status}
                              value={value}
                              required={item.required}
                              onChange={(value) => {
                                let newList = [];

                                onChange(value);

                                if (item.id === 'category') {
                                  if (value.id === 'interiors') {
                                    const list = [...typesList];
                                    newList = list.slice(0, 2);
                                  } else {
                                    newList = typesList;
                                  }
                                  setTypes(newList);
                                }
                              }}
                            ></Dropdown>
                          ) : item.field === 'date' ? (
                            <DatePicker
                              label={item.label}
                              value={value}
                              error={error}
                              isPortal={isTabletOrMobile}
                              onChange={onChange}
                            ></DatePicker>
                          ) : item.field === 'number' ? (
                            <Digit
                              id={item.id}
                              label={item.label}
                              value={value}
                              mask={item.mask}
                              error={error}
                              onChange={onChange}
                              required={item.required}
                            ></Digit>
                          ) : item.field === 'switch' ? (
                            <Switch label={item.label} name={item.id} value={value} onChange={onChange}></Switch>
                          ) : null;
                        }}
                      ></Controller>
                    </div>
                  );
                })}
                <LoadingButton label={'Create'} fullWidth loading={loading} disabled={loading}></LoadingButton>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

CaseStudyCreate.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudyCreate);
