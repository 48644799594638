import styled from 'styled-components';
import tw from 'twin.macro';

export const Arrow = styled.span``;

export const ArrowLeft = styled(Arrow)`
  ${tw`ml-4`}
`;

export const ArrowRight = styled(Arrow)`
  ${tw`mr-4`}
`;

export const CalendarContainer = styled.div`
  width: 300px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  ${tw`bg-white shadow-lg rounded overflow-hidden p-2`};
`;

export const CalendarHeader = styled.div`
  ${tw`flex items-center justify-between`}
`;

export const CalendarGrid = styled.div`
  grid-template: repeat(7, auto) / repeat(7, auto);
  ${tw`grid`};
`;

export const CalendarMonth = styled.div`
  cursor: pointer;
  ${tw`text-black text-base font-semibold p-3 text-center`}
`;

export const CalendarCell = styled.div`
  grid-column: ${(props) => (props.index % 7) + 1} / span 1;
  ${tw`text-center self-center tracking-wide p-3`};
`;

export const CalendarDay = styled(CalendarCell)`
  ${tw`text-sm text-black font-bold`}
`;

export const CalendarDate = styled(CalendarCell)`
  font-weight: ${(props) => (props.inMonth ? 500 : 200)};
  grid-row: ${(props) => Math.floor(props.index / 7) + 2} / span 1;
  transition: all 0.4s ease-out;
  ${tw`cursor-pointer rounded-full hover:text-black hover:bg-black hover:bg-opacity-25`}
`;

export const HighlightedCalendarDate = styled(CalendarDate)`
  ${tw`text-white bg-black hover:text-white hover:bg-opacity-100`}
`;

export const TodayCalendarDate = styled(HighlightedCalendarDate)`
  ${tw`text-black bg-transparent border border-black`}
`;

export const YearGrid = styled.div`
  grid-template: repeat(3, auto) / repeat(3, auto);
  ${tw`grid`};
`;

export const YearCell = styled.div`
  grid-column: ${(props) => (props.index % 3) + 1} / span 1;
  ${tw`text-center self-center tracking-wide p-3`};
`;

export const MonthGrid = styled.div`
  grid-template: repeat(4, auto) / repeat(4, auto);
  ${tw`grid`};
`;

export const MonthCell = styled.div`
  grid-column: ${(props) => (props.index % 4) + 1} / span 1;
  ${tw`text-center self-center tracking-wide p-3`};
`;

export const Item = styled.div`
  transition: all 0.4s ease-out;
  ${tw`p-1 cursor-pointer rounded-full hover:text-black hover:bg-black hover:bg-opacity-25`}
`;

export const HighlightedItem = styled(Item)`
  ${tw`text-white bg-black hover:text-white hover:bg-opacity-100`}
`;
