/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { Tooltip } from 'react-tippy';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './style.scss';

const Password = ({ label, value, placeholder, error, onChange, score, required }) => {
  const [strength, setStrength] = useState(0);
  const [visible, setVisibility] = useState(false);

  useEffect(() => {
    if (score) {
      passwordScore(value);
    }
  }, [value, score]);

  const minStrength = 3;
  const thresholdLength = 8;

  const passwordScore = async (value) => {
    const zxcvbn = await import('zxcvbn');
    setStrength(zxcvbn.default(value).score);
  };

  const passwordLength = value.length;
  const passwordStrong = strength >= minStrength;
  const passwordLong = passwordLength > thresholdLength;

  const counterClass = [
    'px-2 text-xs font-semibold rounded-full',
    passwordLong ? (passwordStrong ? 'badge-success' : 'badge-warning') : 'badge-danger',
  ]
    .join(' ')
    .trim();

  const strengthClass = ['strength-meter mt-2', value.length > 0 ? 'visible' : 'invisible'].join(' ').trim();

  return (
    <div className="password">
      <div className="flex justify-between items-center mb-1">
        <label htmlFor="password" className="block text-sm font-bold text-gray-800">
          {label}{' '}
          {required && (
            <span className="text-red-600" style={{ marginLeft: '2px' }}>
              *
            </span>
          )}
        </label>
        <p className="text-red-600 text-xs text-right font-semibold">{error}</p>
      </div>
      {score ? (
        <Fragment>
          <span className="text-xs">
            Password must follow our{' '}
            <Tooltip
              html={
                <div className="password-policy">
                  <ol className="text-white text-sm text-left list-none">
                    <li>Minimum 8 characters long</li>
                    <li>Atleast 1 uppercase character (A - Z)</li>
                    <li>Atleast 1 lowercase character (a - z)</li>
                    <li>Atleast 1 number (0 - 9)</li>
                    <li>Atleast 1 non-alphanumeric symbol (!@#$%^&amp;*())</li>
                  </ol>
                </div>
              }
            >
              <span
                className="underline text-black font-semibold cursor-pointer"
                onClick={() => {}}
                onKeyDown={() => {}}
                role="button"
                tabIndex="0"
              >
                {' '}
                Password Policy
              </span>
            </Tooltip>
          </span>
          <div className={strengthClass}>
            <div className="strength-meter-fill" data-strength={strength} />
          </div>
        </Fragment>
      ) : null}
      <div className="relative">
        <input
          type={visible ? 'text' : 'password'}
          className={classNames(
            'block p-3 border rounded bg-gray-200 placeholder-gray-600',
            error ? 'border-solid border-red-600' : 'border-transparent'
          )}
          style={{ width: '-webkit-fill-available' }}
          placeholder={placeholder || 'Enter your password'}
          autoComplete="on"
          onChange={onChange}
        ></input>
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
          {visible ? (
            <svg
              fill="black"
              viewBox="0 0 20 20"
              className="h-6 cursor-pointer"
              onClick={() => setVisibility(!visible)}
            >
              <path
                fillRule="evenodd"
                d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                clipRule="evenodd"
              />
              <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
            </svg>
          ) : (
            <svg
              fill="black"
              viewBox="0 0 20 20"
              className="h-6 cursor-pointer"
              onClick={() => setVisibility(!visible)}
            >
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path
                fillRule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                clipRule="evenodd"
              />
            </svg>
          )}
          {score ? (
            <div className="absolute inset-y-0 right-0 mr-10 flex items-center text-sm">
              <span className={counterClass}>
                {passwordLength ? (passwordLong ? `${thresholdLength}+` : passwordLength) : ''}
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

Password.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  score: PropTypes.bool,
  required: PropTypes.bool,
};

export default Password;
