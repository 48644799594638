/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useBus, { dispatch } from 'use-bus';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import numberFormat from 'indian-number-format';
import classNames from 'classnames';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Components
import MaterialLoader from '../../../../components/loader/materialLoader';
import TopProgressBar from '../../../../components/loader/topProgressBar';
import ProgressiveImage from '../../../../components/custom/progressiveImage';

// Actions
import { notificationSuccess, notificationError } from '../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../services/firebase';

// API Services
import OrderAPI from '../../../../services/api/business/order';

const OrderDetails = ({ notificationSuccess, notificationError }) => {
  const history = useHistory();

  const { id } = useParams();

  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    details();
  }, []);

  useBus(
    process.env.REACT_APP_ORDER_DETAILS_REFRESH,
    () => {
      details();
    },
    []
  );

  // API Calls
  const details = async () => {
    setLoading(true);

    try {
      const response = await OrderAPI.details(id);
      const result = response.data;
      setOrder(result);
      setLoading(false);
      dispatch({
        type: process.env.REACT_APP_CHANGE_PAGE_TITLE,
        payload: {
          title: `Order Information`,
        },
      });
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          details();
        } else {
          notificationError(message);
        }
      }
    }
  };

  const downloadInvoice = async () => {
    setDownloading(true);

    try {
      const response = await OrderAPI.downloadInvoice(id);
      const result = response.data;
      const file = new Blob([result], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.download = `Invoice_${id}.pdf`;
      link.href = URL.createObjectURL(file);
      link.click();
      setDownloading(false);
    } catch (error) {
      setDownloading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          details();
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="order-details pt-32 pb-4">
      {downloading && <TopProgressBar />}
      {loading ? (
        <div className="absolute top-0 left-0 flex items-center w-full h-screen">
          <MaterialLoader color="black" size="large" />
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 mb-5 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              onClick={() => history.goBack()}
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path>
            </svg>
          </div>
          <div className="row">
            <div className="grid grid-cols-12 gap-4 w-full">
              <div className="col-span-12 md:col-span-4">
                <div className="flex justify-center">
                  <div style={{ width: 'auto' }} className="">
                    <p className="text-xl font-bold mb-4">Delivery Address</p>
                    <p className="text-base font-bold mb-3">{order.deliveryAddress.name}</p>
                    <p className="mb-3" style={{ lineHeight: '26px' }}>
                      {order.deliveryAddress.address}
                    </p>
                    <p className="text-base mb-3">
                      <span className="font-bold">Phone Number: </span>
                      {order.deliveryAddress.phoneNumber}
                    </p>
                    <p className="text-base">
                      <span className="font-bold">Order ID: </span>#{order.id}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="flex justify-start md:justify-center">
                  <div style={{ width: 'auto' }} className="">
                    <p className="text-xl font-bold mb-4">Cost</p>
                    <p className="text-sm">
                      <span className="font-bold">Product: </span>
                      &#8377;{numberFormat.format(order.cost.product)}
                    </p>
                    <p className="text-sm" style={{ marginTop: '3px' }}>
                      <span
                        className="font-bold cursor-pointer underline"
                        style={{ textDecorationStyle: 'dashed' }}
                        role="presentation"
                        onClick={() => {
                          dispatch({
                            type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                            payload: {
                              type: process.env.REACT_APP_SHIPPING_BREAKDOWN_MODAL,
                              data: order.cost.shippingBreakdown,
                            },
                          });
                        }}
                      >
                        Shipping:{' '}
                      </span>
                      &#8377;{numberFormat.format(order.cost.shippingBreakdown.shipping)}
                    </p>
                    <p className="text-sm" style={{ marginTop: '3px' }}>
                      <span className="font-bold">Convenience Fee: </span>
                      &#8377;{numberFormat.format(Number(order.convenienceFee).toFixed(2))}
                    </p>
                    <p className="text-sm mt-3">
                      <span className="font-bold">Total GST: </span>
                      &#8377;
                      {numberFormat.format(
                        order.cost.sgstFurniture +
                          order.cost.cgstFurniture +
                          order.cost.sgstArt +
                          order.cost.cgstArt +
                          order.cost.sgstPaint +
                          order.cost.cgstPaint
                      )}
                    </p>
                    <p className="text-base mt-1">
                      <span className="font-bold">Total: </span>
                      &#8377;
                      {numberFormat.format(
                        Math.round(
                          order.cost.product +
                            order.cost.sgstFurniture +
                            order.cost.cgstFurniture +
                            order.cost.sgstArt +
                            order.cost.cgstArt +
                            order.cost.sgstPaint +
                            order.cost.cgstPaint +
                            order.cost.shippingBreakdown.shipping +
                            order.convenienceFee
                        )
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-4">
                <div className="flex justify-start md:justify-center">
                  <button
                    className={classNames(
                      'border-0 py-3 px-3 shadow-md bg-black text-white text-base font-semibold opacity-100'
                    )}
                    onClick={() => downloadInvoice()}
                  >
                    <div className="flex items-center justify-center">
                      <span>Download Invoice</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="w-full">
              {order?.orders.map((item, index) => {
                const { size, material, color, product, price, quantity, field, updates } = item;
                const { id, name, thumbnail } = product;

                return (
                  <div key={index} className={classNames(index < order?.orders.length - 1 && 'mb-5')}>
                    <div className="grid grid-cols-12 gap-4 lg:gap-0 w-full product-details">
                      <div className="col-span-12 lg:col-span-5">
                        <div className="product-item flex">
                          <div className="thumbnail">
                            <ProgressiveImage
                              preview={thumbnail.thumbnailURL}
                              src={thumbnail.url}
                              render={(src, style) => (
                                <img className={classNames('w-full')} src={src} style={style} alt={name} />
                              )}
                            ></ProgressiveImage>
                          </div>
                          <div className="flex flex-col w-full">
                            <p
                              className="font-bold text-sm md:text-base cursor-pointer"
                              style={{ marginBottom: '1px' }}
                              role="presentation"
                              onClick={() =>
                                history.push(
                                  `${
                                    field === 'art'
                                      ? '/art-furniture/art/details/'
                                      : field === 'paint'
                                      ? '/others/details/'
                                      : '/art-furniture/furniture/details/'
                                  }${id}`
                                )
                              }
                            >
                              {name}
                            </p>
                            {size && (
                              <p className="text-sm">
                                Size: <span className="font-bold">{size.name}</span>
                              </p>
                            )}
                            {material && (
                              <p className="text-sm">
                                {item.field === 'art' ? 'Variant' : 'Material'}:{' '}
                                <span className="font-bold">{material.name}</span>
                              </p>
                            )}
                            {color && (
                              <div className="flex items-center">
                                <p className="text-sm mr-1">Color:</p>
                                <div
                                  className=""
                                  style={{
                                    backgroundColor: color.name,
                                    width: '20px',
                                    height: '10px',
                                    marginLeft: '3px',
                                  }}
                                ></div>
                              </div>
                            )}
                            {quantity && (
                              <p className="text-sm">
                                Quantity: <span className="font-bold">{quantity}</span>
                              </p>
                            )}
                            <p
                              style={{ marginTop: '3px', marginBottom: '3px' }}
                              className="text-sm md:text-base font-semibold"
                            >
                              &#8377;
                              {numberFormat.format(
                                (price + (size?.price || 0) + (color?.price || 0) + (material?.price || 0)) * quantity
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-12 lg:col-span-7">
                        <div className="tracker flex items-center justify-between w-full h-full">
                          <div className={classNames('order-tracking', updates[0] && 'completed')}>
                            <span className="is-complete"></span>
                            <p className="font-bold">
                              Ordered
                              <br />
                              <span>{moment(updates[0].timestamp).format('ddd, Do MMM')}</span>
                            </p>
                          </div>
                          <div className={classNames('order-tracking', updates[1] && 'completed')}>
                            <span className="is-complete"></span>
                            <p className={classNames(updates[1] && 'font-bold')}>
                              Packed
                              <br />
                              <span>
                                {updates[1]?.timestamp
                                  ? moment(updates[0].timestamp).format('ddd, Do MMM')
                                  : 'INCOMPLETE'}
                              </span>
                            </p>
                          </div>
                          <div className={classNames('order-tracking', updates[2] && 'completed')}>
                            <span className="is-complete"></span>
                            <p className={classNames(updates[2] && 'font-bold')}>
                              Shipped
                              <br />
                              <span>
                                {updates[2]?.timestamp
                                  ? moment(updates[0].timestamp).format('ddd, Do MMM')
                                  : 'INCOMPLETE'}
                              </span>
                            </p>
                          </div>
                          <div className={classNames('order-tracking', updates[3] && 'completed')}>
                            <span className="is-complete"></span>
                            <p className={classNames(updates[3] && 'font-bold')}>
                              Delivered
                              <br />
                              <span>
                                {updates[3]?.timestamp
                                  ? moment(updates[0].timestamp).format('ddd, Do MMM')
                                  : 'INCOMPLETE'}
                              </span>
                            </p>
                          </div>
                          {item.status !== 'Delivered' && (
                            <button
                              className={classNames(
                                'border-0 py-3 px-3 shadow-md bg-black text-white text-sm font-semibold opacity-100'
                              )}
                              onClick={() =>
                                dispatch({
                                  type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                                  payload: {
                                    type: process.env.REACT_APP_ORDER_UPDATE_MODAL,
                                    data: {
                                      orderID: order.id,
                                      id: item.id,
                                      lastUpdate: item.updates[item.updates.length - 1],
                                      field: item.field,
                                    },
                                  },
                                })
                              }
                            >
                              <div className="flex items-center justify-center">
                                <span>Update</span>
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

OrderDetails.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
