/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import useBus, { dispatch } from 'use-bus';
import { useParams, useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { decode } from 'html-entities';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HttpStatusCodes from 'http-status-codes';
import classNames from 'classnames';
import Masonry from 'react-masonry-css';
import PropTypes from 'prop-types';

// Hooks
import useSwiperRef from '../../../../../hooks/useSwiperRef';

// Schema
import FurnitureSchema from '../../../../../data/business/artFurniture/furniture/schema.json';

// Fields
import Input from '../../../../../components/fields/input';
import Digit from '../../../../../components/fields/digit';
import Switch from '../../../../../components/fields/switch';
import Dropdown from '../../../../../components/fields/dropdown';
import Description from '../../../../../components/fields/description';

// Components
import ProgressiveImage from '../../../../../components/custom/progressiveImage';
import TopProgressBar from '../../../../../components/loader/topProgressBar';
import MaterialLoader from '../../../../../components/loader/materialLoader';
import LoadingButton from '../../../../../components/button/loadingButton';

// Actions
import { notificationSuccess, notificationError } from '../../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../../services/firebase';

// API Services
import CategoriesAPI from '../../../../../services/api/master/furniture/categories';
import TypesAPI from '../../../../../services/api/master/furniture/types';
import FurnitureAPI from '../../../../../services/api/business/furniture';

// Strings
import { ui } from '../../../../../strings/list.json';

// Form Validation
const furnitureSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  price: yup.string().required(),
  category: yup.object().required(),
  type: yup.object().required(),
  subType: yup.object().nullable(),
  live: yup.boolean(),
});

const FurnitureDetails = ({ notificationSuccess, notificationError }) => {
  const { id } = useParams();
  const history = useHistory();
  const imageSwiperRef = useRef(null);
  const gallerySwiperRef = useRef(null);

  const [furniture, setFurniture] = useState(null);
  const [categoriesList, setCategories] = useState([]);
  const [typesList, setTypes] = useState([]);
  const [subTypesList, setSubTypes] = useState([]);
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [imageNextEl, imageNextElRef] = useSwiperRef();
  const [imagePrevEl, imagePrevElRef] = useSwiperRef();
  const [galleryNextEl, galleryNextElRef] = useSwiperRef();
  const [galleryPrevEl, galleryPrevElRef] = useSwiperRef();

  const { control, handleSubmit, setValue, errors } = useForm({
    defaultValues: {
      name: '',
      description: '',
      warranty: '',
      eta: '',
      price: 0,
      category: null,
      type: null,
      subType: null,
      live: false,
    },
    resolver: yupResolver(furnitureSchema),
  });

  useEffect(() => {
    categories();
  }, []);

  useEffect(() => {
    if (typesList.length > 0 && type !== null && furniture !== null) {
      if (type === 'T02') {
        const { subTypes } = typesList.filter((item) => {
          return item.id === type;
        })[0];

        setSubTypes(subTypes);
      }
    }
  }, [typesList, type, furniture]);

  useBus(
    process.env.REACT_APP_FURNITURE_REFRESH,
    () => {
      details();
    },
    []
  );

  useBus(
    process.env.REACT_APP_FURNITURE_CROP_MODAL,
    ({ payload }) => {
      const { dataUrl } = payload;

      const data = new FormData();
      data.append('id', furniture.id);
      data.append('image', dataURLtoFile(dataUrl, 'Image'));

      thumbnailUpload(data);
    },
    [furniture]
  );

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      dispatch({
        type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
        payload: {
          layout: process.env.REACT_APP_IMAGE_UPLOAD_MODAL,
          data: {
            type: process.env.REACT_APP_FURNITURE_CROP_MODAL,
            id: null,
            index: 0,
            src: fileReader.result,
          },
        },
      });
    };

    if (file) {
      fileReader.readAsDataURL(file);
    }
  };

  const onSubmit = (data) => {
    const object = {
      id: furniture.id,
      name: data.name,
      description: data.description,
      warranty: data.warranty || null,
      eta: data.eta || null,
      price: Number(data.price),
      category: data.category.id,
      type: data.type.id,
      subType: data.subType ? data.subType.id : null,
      live: data.live,
    };

    update(object);
  };

  const dataURLtoFile = (dataUrl, filename) => {
    const array = dataUrl.split(',');
    const mime = array[0].match(/:(.*?);/)[1];
    const blobString = atob(array[1]);
    let length = blobString.length;
    const uInt8Array = new Uint8Array(length);

    while (length--) {
      uInt8Array[length] = blobString.charCodeAt(length);
    }

    return new File([uInt8Array], filename, { type: mime });
  };

  // API Calls
  const categories = async () => {
    try {
      const response = await CategoriesAPI.list();
      const result = response.data;
      setCategories(result);
      types();
    } catch (error) {
      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          categories();
        } else {
          notificationError(message);
        }
      }
    }
  };

  const types = async () => {
    try {
      const response = await TypesAPI.list();
      const result = response.data;
      setTypes(result);
      details(true);
    } catch (error) {
      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          types();
        } else {
          notificationError(message);
        }
      }
    }
  };

  const details = async (loader) => {
    if (loader) {
      setLoading(true);
    }

    try {
      const response = await FurnitureAPI.details(id);
      const result = response.data;
      setFurniture(result);
      setLoading(false);
      dispatch({
        type: process.env.REACT_APP_CHANGE_PAGE_TITLE,
        payload: {
          title: `Furniture | ${result.name}`,
        },
      });

      setType(result?.type?.id);

      // Set Values
      setValue('name', result.name);
      setValue('description', decode(result.description));
      setValue('warranty', result.warranty || '');
      setValue('eta', result.eta || '');
      setValue('price', result.price);
      setValue('category', result.category);
      setValue('type', result.type);
      setValue('subType', result.subType);
      setValue('live', result.live);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          details();
        } else {
          notificationError(message);
        }
      }
    }
  };

  const thumbnailUpload = async (data) => {
    setDeleting(true);

    try {
      const response = await FurnitureAPI.thumbnailUpload(data);
      const { message } = response.data;

      setDeleting(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setDeleting(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          thumbnailUpload(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const dimensionDelete = async (id, dimensionID) => {
    setDeleting(true);

    try {
      const response = await FurnitureAPI.dimensionDelete({
        id,
        dimensionID,
      });
      const { message } = response.data;

      setDeleting(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setDeleting(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          dimensionDelete(id, dimensionID);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const sizeDelete = async (id, sizeID) => {
    setDeleting(true);

    try {
      const response = await FurnitureAPI.sizeDelete({
        id,
        sizeID,
      });
      const { message } = response.data;

      setDeleting(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setDeleting(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          sizeDelete(id, sizeID);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const materialDelete = async (id, materialID) => {
    setDeleting(true);

    try {
      const response = await FurnitureAPI.materialDelete({
        id,
        materialID,
      });
      const { message } = response.data;

      setDeleting(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setDeleting(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          materialDelete(id, materialID);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const colorDelete = async (id, colorID) => {
    setDeleting(true);

    try {
      const response = await FurnitureAPI.colorDelete({
        id,
        colorID,
      });
      const { message } = response.data;

      setDeleting(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setDeleting(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          colorDelete(id, colorID);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const imageDelete = async (data) => {
    setDeleting(true);

    try {
      const response = await FurnitureAPI.imageDelete(data);
      const { message } = response.data;

      setDeleting(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setDeleting(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          imageDelete(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const update = async (data) => {
    setUpdating(true);

    try {
      const response = await FurnitureAPI.update(data);
      const { message } = response.data;

      setUpdating(false);
      notificationSuccess(message);
      details();
    } catch (error) {
      setUpdating(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          update(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="furniture-details pt-32">
      {deleting && <TopProgressBar />}
      {loading ? (
        <div className="absolute top-0 left-0 flex items-center w-full h-screen">
          <MaterialLoader color="black" size="large" />
        </div>
      ) : (
        <div className="container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 mb-5 ml-4 md:ml-8 cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            onClick={() => history.goBack()}
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path>
          </svg>
          <div className="row">
            <div className="grid grid-cols-12 gap-6 w-full">
              <div className="col-span-12 sm:col-span-6">
                <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                  {FurnitureSchema.map((item, index) => {
                    const error = ui.errors[errors[item.id]?.type] || errors[item.id]?.message;

                    return (
                      <div key={index} className="mb-4">
                        <Controller
                          name={item.id}
                          control={control}
                          render={({ value, onChange }) => {
                            return item.field === 'input' ? (
                              <Input
                                id={item.id}
                                label={item.label}
                                type={item.type}
                                value={value}
                                error={error}
                                required={item.required}
                                onChange={onChange}
                              ></Input>
                            ) : item.field === 'description' ? (
                              <Description
                                id={item.id}
                                label={item.label}
                                value={value}
                                error={error}
                                required={item.required}
                                onChange={onChange}
                              ></Description>
                            ) : item.field === 'dropdown' ? (
                              item.id === 'category' ||
                              item.id === 'type' ||
                              (item.id === 'subType' && type === 'T02') ? (
                                <Dropdown
                                  label={item.label}
                                  name={item.id}
                                  options={
                                    item.id === 'category'
                                      ? categoriesList
                                      : item.id === 'type'
                                      ? typesList
                                      : subTypesList
                                  }
                                  value={value}
                                  required={item.required}
                                  onChange={(value) => {
                                    if (item.id === 'type') {
                                      setType(value?.id);

                                      if (value?.id === 'T02') {
                                        setValue('subType', value?.subTypes[0]);
                                        setSubTypes(value?.subTypes);
                                      } else {
                                        setValue('subType', null);
                                        setSubTypes([]);
                                      }
                                    } else {
                                      setValue('subType', null);
                                      setSubTypes([]);
                                    }

                                    onChange(value);
                                  }}
                                ></Dropdown>
                              ) : null
                            ) : item.field === 'number' ? (
                              <Digit
                                id={item.id}
                                label={item.label}
                                value={value}
                                mask={item.mask}
                                error={error}
                                onChange={onChange}
                                required={item.required}
                              ></Digit>
                            ) : item.field === 'switch' ? (
                              <Switch label={item.label} name={item.id} value={value} onChange={onChange}></Switch>
                            ) : null;
                          }}
                        ></Controller>
                      </div>
                    );
                  })}
                  <LoadingButton label={'Update'} fullWidth loading={updating} disabled={updating}></LoadingButton>
                </form>
              </div>
              <div className="col-span-12 sm:col-span-6">
                <div className="furniture-thumbnail pb-3">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-base font-bold">Thumbnail</span>
                    <input accept="image/*" hidden id="image" type="file" onChange={handleImageChange} />
                    <label htmlFor="image" type="button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 cursor-pointer"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        onClick={() => {}}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </label>
                  </div>
                  {furniture?.thumbnail?.url && (
                    <ProgressiveImage
                      preview={furniture?.thumbnail?.thumbnailURL}
                      src={furniture?.thumbnail?.url}
                      render={(src, style) => (
                        <img className={classNames('w-40 h-40')} src={src} style={style} alt={''} />
                      )}
                    ></ProgressiveImage>
                  )}
                </div>
                <div className="furniture-dimension">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-base font-bold">Dimensions</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      onClick={() =>
                        dispatch({
                          type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                          payload: {
                            type: process.env.REACT_APP_FURNITURE_MODAL,
                            layout: process.env.REACT_APP_FURNITURE_DIMENSION_MODAL,
                            data: {
                              edit: false,
                              id: furniture.id,
                            },
                          },
                        })
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <Masonry
                    breakpointCols={{
                      default: 3,
                      1024: 3,
                      768: 2,
                      425: 1,
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-grid-column"
                  >
                    {(furniture?.dimensions).map((item, index) => (
                      <div key={index} className={classNames('border-2 border-solid border-transparent')}>
                        <div
                          className="p-3 text-sm cursor-pointer shadow-md rounded-md border border-solid border-gray-200"
                          style={{ backgroundColor: '#ffffff' }}
                        >
                          <p>
                            Name: <span className="font-semibold">{item.name}</span>
                          </p>
                          <p>
                            Value: <span className="font-semibold">{item.value}</span>
                          </p>
                          <div className="flex justify-start" style={{ marginTop: '10px', fontSize: '13px' }}>
                            <span
                              className="underline cursor-pointer"
                              role="presentation"
                              onClick={() =>
                                dispatch({
                                  type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                                  payload: {
                                    type: process.env.REACT_APP_FURNITURE_MODAL,
                                    layout: process.env.REACT_APP_FURNITURE_DIMENSION_MODAL,
                                    data: {
                                      edit: true,
                                      id: furniture.id,
                                      dimension: item,
                                    },
                                  },
                                })
                              }
                            >
                              Edit
                            </span>
                            <span
                              className="underline cursor-pointer ml-2"
                              role="presentation"
                              onClick={() => dimensionDelete(furniture.id, item.id)}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </div>
                <div className="furniture-size">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-base font-bold">Size</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      onClick={() =>
                        dispatch({
                          type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                          payload: {
                            type: process.env.REACT_APP_FURNITURE_MODAL,
                            layout: process.env.REACT_APP_FURNITURE_SIZE_MODAL,
                            data: {
                              edit: false,
                              id: furniture.id,
                            },
                          },
                        })
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <Masonry
                    breakpointCols={{
                      default: 3,
                      1024: 3,
                      768: 2,
                      425: 1,
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-grid-column"
                  >
                    {furniture?.size.map((item, index) => (
                      <div key={index} className={classNames('border-2 border-solid border-transparent')}>
                        <div
                          className="p-3 text-sm cursor-pointer shadow-md rounded-md border border-solid border-gray-200"
                          style={{ backgroundColor: '#ffffff' }}
                        >
                          <p>
                            Name: <span className="font-semibold">{item.name}</span>
                          </p>
                          <p>
                            Price: <span className="font-semibold">{item.price}</span>
                          </p>
                          <p>
                            Weight (KG): <span className="font-semibold">{item.weight}</span>
                          </p>
                          <div className="flex justify-start" style={{ marginTop: '10px', fontSize: '13px' }}>
                            <span
                              className="underline cursor-pointer"
                              role="presentation"
                              onClick={() =>
                                dispatch({
                                  type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                                  payload: {
                                    type: process.env.REACT_APP_FURNITURE_MODAL,
                                    layout: process.env.REACT_APP_FURNITURE_SIZE_MODAL,
                                    data: {
                                      edit: true,
                                      id: furniture.id,
                                      size: item,
                                    },
                                  },
                                })
                              }
                            >
                              Edit
                            </span>
                            <span
                              className="underline cursor-pointer ml-2"
                              role="presentation"
                              onClick={() => sizeDelete(furniture.id, item.id)}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </div>
                <div className="furniture-material">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-base font-bold">Material</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      onClick={() =>
                        dispatch({
                          type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                          payload: {
                            type: process.env.REACT_APP_FURNITURE_MODAL,
                            layout: process.env.REACT_APP_FURNITURE_MATERIAL_MODAL,
                            data: {
                              edit: false,
                              id: furniture.id,
                            },
                          },
                        })
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <Masonry
                    breakpointCols={{
                      default: 3,
                      1024: 3,
                      768: 2,
                      425: 1,
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-grid-column"
                  >
                    {furniture?.material.map((item, index) => (
                      <div key={index} className={classNames('border-2 border-solid border-transparent')}>
                        <div
                          className="p-3 text-sm cursor-pointer shadow-md rounded-md border border-solid border-gray-200"
                          style={{ backgroundColor: '#ffffff' }}
                        >
                          <p>
                            Name: <span className="font-semibold">{item.name}</span>
                          </p>
                          <p>
                            Price: <span className="font-semibold">{item.price}</span>
                          </p>
                          <div className="flex justify-start" style={{ marginTop: '10px', fontSize: '13px' }}>
                            <span
                              className="underline cursor-pointer"
                              role="presentation"
                              onClick={() =>
                                dispatch({
                                  type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                                  payload: {
                                    type: process.env.REACT_APP_FURNITURE_MODAL,
                                    layout: process.env.REACT_APP_FURNITURE_MATERIAL_MODAL,
                                    data: {
                                      edit: true,
                                      id: furniture.id,
                                      material: item,
                                    },
                                  },
                                })
                              }
                            >
                              Edit
                            </span>
                            <span
                              className="underline cursor-pointer ml-2"
                              role="presentation"
                              onClick={() => materialDelete(furniture.id, item.id)}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </div>
                <div className="furniture-color">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-bold">Colors</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      onClick={() =>
                        dispatch({
                          type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                          payload: {
                            type: process.env.REACT_APP_FURNITURE_MODAL,
                            layout: process.env.REACT_APP_FURNITURE_COLOR_MODAL,
                            data: {
                              edit: false,
                              id: furniture.id,
                            },
                          },
                        })
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <Masonry
                    breakpointCols={{
                      default: 4,
                      1024: 4,
                      768: 3,
                      425: 2,
                    }}
                    className="masonry-grid"
                    columnClassName="masonry-grid-column"
                  >
                    {furniture?.color.map((item, index) => (
                      <div key={index} className={classNames('border-2 border-solid border-transparent')}>
                        <div
                          className="p-3 text-sm cursor-pointer shadow-md rounded-md border border-solid border-gray-200"
                          style={{ backgroundColor: '#ffffff' }}
                        >
                          <div className="flex items-center justify-between">
                            <span>Color: </span>
                            <div
                              className="w-5 h-5 rounded-full cursor-pointer"
                              style={{ backgroundColor: item.name }}
                            ></div>
                          </div>
                          <div className="mt-1">
                            <p>
                              Price: <span className="font-semibold">{item.price}</span>
                            </p>
                          </div>
                          <div className="flex justify-start" style={{ marginTop: '10px', fontSize: '13px' }}>
                            <span
                              className="underline cursor-pointer"
                              role="presentation"
                              onClick={() =>
                                dispatch({
                                  type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                                  payload: {
                                    type: process.env.REACT_APP_FURNITURE_MODAL,
                                    layout: process.env.REACT_APP_FURNITURE_COLOR_MODAL,
                                    data: {
                                      edit: true,
                                      id: furniture.id,
                                      color: item,
                                    },
                                  },
                                })
                              }
                            >
                              Edit
                            </span>
                            <span
                              className="underline cursor-pointer ml-2"
                              role="presentation"
                              onClick={() => colorDelete(furniture.id, item.id)}
                            >
                              Delete
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </div>
                <div className="furniture-images pb-3">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-bold">Images</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      onClick={() =>
                        dispatch({
                          type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                          payload: {
                            type: process.env.REACT_APP_FURNITURE_MODAL,
                            layout: process.env.REACT_APP_FURNITURE_IMAGES_MODAL,
                            data: {
                              id: furniture.id,
                              gallery: 0,
                            },
                          },
                        })
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <Swiper
                    ref={imageSwiperRef}
                    style={{ '--swiper-navigation-color': '#ffffff', '--swiper-pagination-color': '#ffffff' }}
                    onSwiper={(value) => {}}
                    spaceBetween={10}
                    slidesPerView={3}
                    navigation={{
                      imagePrevEl,
                      imageNextEl,
                    }}
                    freeMode={true}
                    centeredSlidesBounds={false}
                    watchSlidesVisibility={true}
                    watchSlidesProgress={true}
                    observer={true}
                    observeParents={true}
                    className="relative"
                  >
                    {furniture?.images.map((item, index) => (
                      <SwiperSlide key={index} className="cursor-pointer">
                        <Fragment>
                          <img src={item.thumbnail} alt={furniture?.name || ''} className="w-full" />
                          <p
                            className="text-sm text-center underline cursor-pointer mt-2"
                            role="presentation"
                            onClick={() => {
                              const data = {
                                id: furniture.id,
                                gallery: 0,
                                fileId: item.fileId,
                              };

                              imageDelete(data);
                            }}
                          >
                            Delete
                          </p>
                        </Fragment>
                      </SwiperSlide>
                    ))}
                    {furniture?.images.length > 3 && (
                      <Fragment>
                        <div className="h-full flex items-center absolute top-0 left-0 z-10 px-1">
                          <button
                            ref={imagePrevElRef}
                            className="bg-white flex items-center justify-center rounded-full shadow-md w-6 h-6 cursor-pointer"
                            onClick={() => imageSwiperRef.current.swiper.slidePrev()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16l-4-4m0 0l4-4m-4 4h18"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        <div className="h-full flex items-center absolute top-0 right-0 z-10 px-1">
                          <button
                            ref={imageNextElRef}
                            className="bg-white flex items-center justify-center rounded-full shadow-md w-6 h-6 cursor-pointer"
                            onClick={() => imageSwiperRef.current.swiper.slideNext()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </Fragment>
                    )}
                  </Swiper>
                </div>
                <div className="furniture-gallery pb-3">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-bold">Gallery</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 cursor-pointer"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      onClick={() =>
                        dispatch({
                          type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                          payload: {
                            type: process.env.REACT_APP_FURNITURE_MODAL,
                            layout: process.env.REACT_APP_FURNITURE_IMAGES_MODAL,
                            data: {
                              id: furniture.id,
                              gallery: 1,
                            },
                          },
                        })
                      }
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </div>
                  <Swiper
                    ref={gallerySwiperRef}
                    style={{ '--swiper-navigation-color': '#ffffff', '--swiper-pagination-color': '#ffffff' }}
                    onSwiper={(value) => {}}
                    spaceBetween={10}
                    slidesPerView={3}
                    navigation={{
                      galleryPrevEl,
                      galleryNextEl,
                    }}
                    freeMode={true}
                    centeredSlidesBounds={false}
                    watchSlidesVisibility={true}
                    watchSlidesProgress={true}
                    observer={true}
                    observeParents={true}
                    className="relative"
                  >
                    {furniture?.gallery.map((item, index) => (
                      <SwiperSlide key={index} className="cursor-pointer">
                        <Fragment>
                          <img src={item.thumbnail} alt={furniture?.name || ''} className="w-full" />
                          <p
                            className="text-sm text-center underline cursor-pointer mt-2"
                            role="presentation"
                            onClick={() => {
                              const data = {
                                id: furniture.id,
                                gallery: 1,
                                fileId: item.fileId,
                              };

                              imageDelete(data);
                            }}
                          >
                            Delete
                          </p>
                        </Fragment>
                      </SwiperSlide>
                    ))}
                    {furniture?.gallery.length > 3 && (
                      <Fragment>
                        <div className="h-full flex items-center absolute top-0 left-0 z-10 px-1">
                          <button
                            ref={galleryPrevElRef}
                            className="bg-white flex items-center justify-center rounded-full shadow-md w-6 h-6 cursor-pointer"
                            onClick={() => gallerySwiperRef.current.swiper.slidePrev()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16l-4-4m0 0l4-4m-4 4h18"
                              ></path>
                            </svg>
                          </button>
                        </div>
                        <div className="h-full flex items-center absolute top-0 right-0 z-10 px-1">
                          <button
                            ref={galleryNextElRef}
                            className="bg-white flex items-center justify-center rounded-full shadow-md w-6 h-6 cursor-pointer"
                            onClick={() => gallerySwiperRef.current.swiper.slideNext()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </Fragment>
                    )}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

FurnitureDetails.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(FurnitureDetails);
