import api from '../../axios';

export default {
  list: () => {
    return api({
      url: `/business/paint`,
      method: `GET`,
    });
  },
  inactive: () => {
    return api({
      url: `/business/paint/inactive`,
      method: `GET`,
    });
  },
  search: (term, active) => {
    return api({
      url: `/business/paint/search`,
      method: `GET`,
      params: { term, active },
    });
  },
  create: (data) => {
    return api({
      url: `/business/paint/create`,
      method: `POST`,
      data: data,
    });
  },
  details: (id) => {
    return api({
      url: `/business/paint/details/${id}`,
      method: `GET`,
    });
  },
  thumbnailUpload: (data) => {
    return api({
      url: `/business/paint/thumbnail/upload`,
      method: `POST`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  quantityCreate: (data) => {
    return api({
      url: `/business/paint/quantity/create`,
      method: `POST`,
      data: data,
    });
  },
  quantityUpdate: (data) => {
    return api({
      url: `/business/paint/quantity/update`,
      method: `POST`,
      data: data,
    });
  },
  quantityDelete: (data) => {
    return api({
      url: `/business/paint/quantity/delete`,
      method: `POST`,
      data: data,
    });
  },
  colorCreate: (data) => {
    return api({
      url: `/business/paint/color/create`,
      method: `POST`,
      data: data,
    });
  },
  colorUpdate: (data) => {
    return api({
      url: `/business/paint/color/update`,
      method: `POST`,
      data: data,
    });
  },
  colorDelete: (data) => {
    return api({
      url: `/business/paint/color/delete`,
      method: `POST`,
      data: data,
    });
  },
  imageDelete: (data) => {
    return api({
      url: `/business/paint/image/delete`,
      method: `POST`,
      data: data,
    });
  },
  update: (data) => {
    return api({
      url: `/business/paint/update`,
      method: `PATCH`,
      data: data,
    });
  },
};
