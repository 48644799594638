/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import { useHistory } from 'react-router-dom';
import Masonry from 'react-masonry-css';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Components
import Dropdown from '../../../../components/custom/dropdown/general';
import TopProgressBar from '../../../../components/loader/topProgressBar';
import ArtTile from '../../../../components/custom/business/product/tile';

// Actions
import { notificationSuccess, notificationError } from '../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../services/firebase';

// API Services
import ArtAPI from '../../../../services/api/business/art';

// Options
const options = [
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'inactive',
    name: 'Inactive',
  },
];

const Art = ({ notificationSuccess, notificationError }) => {
  const history = useHistory();

  const [list, setList] = useState([]);
  const [term, setTerm] = useState('');
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    artList(true);
  }, []);

  // API Calls
  const artList = async (loader) => {
    if (loader) {
      setLoading(true);
    }

    try {
      const response = await ArtAPI.list();
      const result = response.data;
      setList(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      }
    }
  };

  const artListInactive = async (loader) => {
    if (loader) {
      setLoading(true);
    }

    try {
      const response = await ArtAPI.inactive();
      const result = response.data;
      setList(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          artListInactive(loader);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const search = async (term) => {
    try {
      const response = await ArtAPI.search(term, active);
      const result = response.data;
      setLoading(false);
      setList(result);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          search(term);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="art">
      {loading ? (
        <TopProgressBar />
      ) : (
        <Fragment>
          <div className="row">
            <div className="w-full h-12 flex items-center justify-between relative mb-4">
              <button
                className={
                  'border-0 py-3 px-3 shadow-md bg-black text-white text-sm font-semibold opacity-100 cursor-pointer'
                }
                onClick={() => history.push('/art-furniture/art/create')}
              >
                <div className="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mr-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <span>Create New</span>
                </div>
              </button>
              <div className="search-box">
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search By Name"
                  value={term}
                  onChange={(event) => {
                    const value = event.target.value;

                    search(value);
                    setTerm(value);
                  }}
                ></input>
                <span className="search-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </span>
              </div>
              <div className="absolute right-0" style={{ marginTop: '-116px' }}>
                <Dropdown
                  value={active ? 'Active' : 'Inactive'}
                  options={options}
                  onSelect={(value) => {
                    const active = value.name === 'Active';

                    if (active) {
                      artList(true);
                    } else {
                      artListInactive(true);
                    }

                    setActive(active);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="w-full">
              <Masonry
                breakpointCols={{
                  default: 4,
                  1024: 3,
                  768: 2,
                  425: 1,
                }}
                className="masonry-grid"
                columnClassName="masonry-grid-column"
              >
                {list.map((item, index) => (
                  <ArtTile
                    key={index}
                    id={item.id}
                    image={item.image}
                    name={item.name}
                    price={item.price}
                    link={'/art-furniture/art/details/'}
                  />
                ))}
              </Masonry>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Art.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Art);
