import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from './types';

export const notificationSuccess = (message) => ({
  type: NOTIFICATION_SUCCESS,
  payload: {
    message,
  },
});

export const notificationError = (message) => ({
  type: NOTIFICATION_ERROR,
  payload: {
    message,
  },
});
