import React, { Fragment, useState } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

// Calendar
import Calendar from './calendar';

const DatePicker = ({
  label,
  value,
  error,
  isPortal,
  backward,
  forward,
  disabled,
  disableFuture,
  disablePast,
  events,
  onChange,
}) => {
  const date = value ? moment(value) : '';
  const [showCalendar, setCalendarVisibility] = useState(false);
  const [inputElement, setInputElement] = useState(null);
  const [calendarElement, setCalendarElement] = useState(null);

  const { styles, attributes } = usePopper(inputElement, calendarElement, {
    modifiers: [],
    strategy: 'fixed',
  });

  const abbr = isPortal ? null : { ...attributes.popper };

  const handleBackward = (month, year) => {
    if (month === 1) {
      month = 12;
      year = year - 1;
    } else {
      month = month - 1;
    }

    if (backward) {
      backward(moment(month, 'M').format('MMMM'), year);
    }
  };

  const handleForward = (month, year) => {
    if (month === 12) {
      month = 1;
      year = year + 1;
    } else {
      month = month + 1;
    }

    if (forward) {
      forward(moment(month, 'M').format('MMMM'), year);
    }
  };

  return (
    <Fragment>
      <div className="flex justify-between items-center mb-2">
        <label htmlFor="date" className="block text-sm font-bold text-gray-800">
          {label || 'Date'}
        </label>
        <p className="text-red-600 text-xs text-right font-semibold">{error}</p>
      </div>
      <input
        ref={setInputElement}
        className={classNames(
          'block p-3 border rounded bg-gray-200 placeholder-gray-600',
          error ? 'border-solid border-red-600' : 'border-transparent'
        )}
        style={{ width: '-webkit-fill-available' }}
        placeholder={'DD/MM/YYYY'}
        value={date && date.format('DD/MM/YYYY')}
        onClick={() => (disabled ? null : setCalendarVisibility((showCalendar) => !showCalendar))}
        readOnly
      />
      {showCalendar && (
        <div
          className={classNames(
            isPortal ? 'w-full h-full flex justify-center items-center absolute top-0 right-0' : null
          )}
        >
          <div
            ref={isPortal ? null : setCalendarElement}
            style={isPortal ? null : styles.popper}
            className={classNames(
              'calendar-container z-10',
              isPortal ? 'datepicker-portal flex items-center justify-center' : null
            )}
            {...abbr}
          >
            <OutsideClickHandler
              onOutsideClick={() => {
                if (showCalendar) {
                  setCalendarVisibility(false);
                }
              }}
            >
              <Calendar
                date={date || moment()}
                onDateChanged={(date) => {
                  onChange(date);
                  setCalendarVisibility((showCalendar) => !showCalendar);
                }}
                backward={handleBackward}
                forward={handleForward}
                isPortal={isPortal}
                events={events}
                disableFuture={disableFuture}
                disablePast={disablePast}
              />
            </OutsideClickHandler>
          </div>
        </div>
      )}
    </Fragment>
  );
};

DatePicker.defaultProps = {
  backward: null,
  forward: null,
  disableFuture: false,
  disablePast: false,
  events: [],
};

DatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  error: PropTypes.string,
  isPortal: PropTypes.bool,
  backward: PropTypes.func,
  forward: PropTypes.func,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  events: PropTypes.array,
  onChange: PropTypes.func,
};

export default DatePicker;
