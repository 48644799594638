import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Modals
import FormModal from './form';
import VideoModal from './video';

const Team = ({ modal, data }) => {
  return (
    <Fragment>
      {modal === process.env.REACT_APP_TEAM_CRUD_MODAL ? <FormModal {...data}></FormModal> : <VideoModal {...data} />}
    </Fragment>
  );
};

Team.propTypes = {
  modal: PropTypes.string,
  data: PropTypes.object,
};

export default Team;
