import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import rootReducer from './root/reducers';
import rootSaga from './root/sagas';

const sagaMiddleware = createSagaMiddleware();

const dev = process.env.NODE_ENV !== 'production';

const middlewares = [dev && logger, thunk, sagaMiddleware].filter(Boolean);

const bindMiddleware = (middleware) => {
  if (dev) {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const store = createStore(rootReducer, bindMiddleware(middlewares));

sagaMiddleware.run(rootSaga);

export default store;
