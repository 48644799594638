/* eslint-disable require-jsdoc */
import { takeEvery, fork, all, call } from 'redux-saga/effects';

// Notification Redux States
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from './types';

// Toast
import cogoToast from 'cogo-toast';

function* success({ payload: { message } }) {
  yield call(cogoToast.success, message);
}

function* error({ payload: { message } }) {
  yield call(cogoToast.error, message);
}

export function* watchNotification() {
  yield takeEvery(NOTIFICATION_SUCCESS, success);
  yield takeEvery(NOTIFICATION_ERROR, error);
}

function* notificationSaga() {
  yield all([fork(watchNotification)]);
}

export default notificationSaga;
