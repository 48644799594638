// Authentication Pages
import Login from '../pages/authentication/login';

// Main Pages
import Dashboard from '../pages/main/dashboard';

// Nav Links
import Config from '../pages/navLinks/config';
import Highlight from '../pages/navLinks/highlight';
import Testimonials from '../pages/navLinks/testimonials';
import Orders from '../pages/navLinks/orders';
import CaseStudy from '../pages/navLinks/caseStudy';
import ArtFurniture from '../pages/navLinks/artFurniture';
import Others from '../pages/navLinks/others';
import Teams from '../pages/navLinks/team';
import CareerEnquire from '../pages/navLinks/careerEnquire';

// Create
import CaseStudyCreate from '../pages/navLinks/caseStudy/crud/create';
import ArtCreate from '../pages/navLinks/artFurniture/art/create';
import FurnitureCreate from '../pages/navLinks/artFurniture/furniture/create';
import OthersCreate from '../pages/navLinks/others/create';

// Details
import OrdersDetails from '../pages/navLinks/orders/details';
import CaseStudyDetails from '../pages/navLinks/caseStudy/crud/details';
import ArtDetails from '../pages/navLinks/artFurniture/art/details';
import FurnitureDetails from '../pages/navLinks/artFurniture/furniture/details';
import OthersDetails from '../pages/navLinks/others/details';
import CareerDetails from '../pages/navLinks/careerEnquire/careers/details';
import EnquiryDetails from '../pages/navLinks/careerEnquire/enquiries/details';

const routes = [
  { path: '/login', component: Login, isPublic: true, restricted: true },
  { path: '/dashboard', component: Dashboard },
  { path: '/config', component: Config },
  { path: '/highlights', component: Highlight },
  { path: '/testimonials', component: Testimonials },
  { path: '/orders', component: Orders },
  { path: '/orders/details/:id', component: OrdersDetails },
  { path: '/case-study', component: CaseStudy },
  { path: '/case-study/create', component: CaseStudyCreate },
  { path: '/case-study/details/:id', component: CaseStudyDetails },
  { path: '/art-furniture', component: ArtFurniture },
  { path: '/art-furniture/art/create', component: ArtCreate },
  { path: '/art-furniture/art/details/:id', component: ArtDetails },
  { path: '/art-furniture/furniture/create', component: FurnitureCreate },
  { path: '/art-furniture/furniture/details/:id', component: FurnitureDetails },
  { path: '/others', component: Others },
  { path: '/others/create', component: OthersCreate },
  { path: '/others/details/:id', component: OthersDetails },
  { path: '/team', component: Teams },
  { path: '/career-enquire', component: CareerEnquire },
  { path: '/career-enquire/careers/:id', component: CareerDetails },
  { path: '/career-enquire/enquiries/:id', component: EnquiryDetails },
];

export default routes;
