/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Fragment } from 'react';
import classNames from 'classnames';
import useBus from 'use-bus';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { ReactComponent as UpArrow } from '../../../assets/icons/up-arrow-circle.svg';
import { openMenu, closeMenu } from '../../../animations/navigation/header/menuAnimations';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

// Componenets
import ProfileDropdowm from '../../custom/dropdown/profile';
import Avatar from '../../user/avatar';

// Nav List
import NavList from './data/navList.json';

const Header = ({ session }) => {
  const header = useRef(null);

  const [menuState, setMenuState] = useState({ menuOpened: false });
  const [subMenu, setSubMenu] = useState(null);

  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024 });

  useEffect(() => {
    if (isTabletOrMobile) {
      if (menuState.menuOpened === true) {
        if (header.current.classList.length > 1) {
          header.current.classList.add('header--menu-opened');
        }
        openMenu();
      } else if (menuState.menuOpened === false) {
        header.current.classList.remove('header--menu-opened');
        closeMenu();
      }

      localStorage.setItem(process.env.REACT_APP_TABLET_MENU_STATE, JSON.stringify(menuState.menuOpened));
    }
  }, [menuState]);

  useBus(
    process.env.REACT_APP_TAB_NAV_CLOSE,
    () => {
      closeMenu();
      header.current.classList.remove('header--menu-opened');
      localStorage.setItem(process.env.REACT_APP_TABLET_MENU_STATE, JSON.stringify(false));
    },
    [header]
  );

  const { displayName, photoURL } = session?.user;

  return (
    <OutsideClickHandler onOutsideClick={() => setSubMenu(null)}>
      <div ref={header} className={classNames('header')}>
        <div className="container">
          <div className="row v-center space-between">
            <div className={classNames('logo', !isTabletOrMobile && 'absolute')}>
              <NavLink to="/" exact className="flex items-center">
                <img src="/images/static/logo.png" className="w-10 h-10 xl:w-12 xl:h-12 mr-2" alt="" />
                BND.
              </NavLink>
            </div>
            {isTabletOrMobile ? (
              <div className="flex items-center">
                {session.logged && (
                  <ProfileDropdowm size={'small'}>
                    <Avatar name={displayName} thumbnail={null} url={photoURL} size="small" showArrow={false} />
                  </ProfileDropdowm>
                )}
                <div className="nav-toggle ml-5">
                  <div
                    onClick={() => setMenuState({ menuOpened: true })}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex="0"
                    className="hamburger-menu"
                  >
                    <span></span>
                    <span></span>
                  </div>
                  <div
                    className="hamburger-menu-close"
                    onClick={() => setMenuState({ menuOpened: false })}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex="0"
                  >
                    <UpArrow />
                  </div>
                </div>
              </div>
            ) : (
              <nav className="w-full flex relative large">
                <ul className="mx-auto">
                  {session.logged
                    ? NavList.center.map((item, index) => (
                        <li key={index}>
                          {item.link ? (
                            <NavLink className="menu-item" to={item.link} exact onClick={() => setSubMenu(null)}>
                              {item.name}
                            </NavLink>
                          ) : (
                            <Fragment>
                              <button
                                className="menu-item"
                                onClick={() =>
                                  setSubMenu({
                                    [item.name]: true,
                                  })
                                }
                              >
                                {item.name}
                              </button>
                              <div
                                className={classNames(
                                  'sub-menu mega-menu rounded-br-lg',
                                  subMenu?.[item.name] && 'open'
                                )}
                              >
                                {item.subMenu.map((menu, index) => {
                                  return (
                                    <div className="list-item text-center" key={index}>
                                      <Link to={menu.link} className="no-underline" onClick={() => setSubMenu(null)}>
                                        <h4 className="title">{menu.title}</h4>
                                      </Link>
                                    </div>
                                  );
                                })}
                              </div>
                            </Fragment>
                          )}
                        </li>
                      ))
                    : null}
                </ul>
                <div className="flex absolute right-0">
                  <ul>
                    {session.logged
                      ? NavList.right.map((item, index) => (
                          <li key={index}>
                            <NavLink className="menu-item" to={item.link} exact onClick={() => setSubMenu(null)}>
                              {item.name}
                            </NavLink>
                          </li>
                        ))
                      : null}
                  </ul>
                  <div className="flex items-center ml-1">
                    {session.loading ? null : session.logged ? (
                      <ProfileDropdowm>
                        <Avatar name={displayName} thumbnail={null} url={photoURL} size="medium" />
                      </ProfileDropdowm>
                    ) : null}
                  </div>
                </div>
              </nav>
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({});

Header.propTypes = {
  session: PropTypes.object,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
