import { SESSION_ACTIVE, SESSION_INACTIVE } from './types';

export const sessionActive = (idToken, user) => ({
  type: SESSION_ACTIVE,
  payload: {
    idToken,
    user,
  },
});

export const sessionInactive = () => ({
  type: SESSION_INACTIVE,
});
