import api from '../../axios';

export default {
  list: (active) => {
    return api({
      url: `/business/enquire/list`,
      method: `GET`,
      params: {
        active: active,
      },
    });
  },
  details: (id) => {
    return api({
      url: `/business/enquire/details/${id}`,
      method: `GET`,
    });
  },
  update: (data) => {
    return api({
      url: `/business/enquire/update`,
      method: `PATCH`,
      data: data,
    });
  },
};
