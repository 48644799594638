import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const TextArea = ({ id, label, error, rows, disabled, info, description, required, ...props }) => {
  return (
    <div className="textarea">
      <div className="flex justify-between items-center mb-2">
        <label htmlFor={id} className="text-sm font-bold text-gray-800 flex items-center">
          {label}{' '}
          {required && (
            <span className="text-red-600" style={{ marginLeft: '2px' }}>
              *
            </span>
          )}
        </label>
        <p className="text-red-600 text-xs text-right font-semibold">{error}</p>
      </div>
      <textarea
        {...props}
        rows={rows || 5}
        className={classNames(
          'block p-3 border rounded bg-gray-200 placeholder-gray-600 resize-none',
          error ? 'border-solid border-red-600' : 'border-transparent'
        )}
        style={{ width: '-webkit-fill-available' }}
        disabled={disabled}
      ></textarea>
    </div>
  );
};

TextArea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  info: PropTypes.bool,
  description: PropTypes.string,
  required: PropTypes.bool,
};

export default TextArea;
