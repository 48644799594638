import api from '../../axios';

export default {
  list: () => {
    return api({
      url: `/business/testimonial/list`,
      method: `GET`,
    });
  },
  create: (data) => {
    return api({
      url: `/business/testimonial/create`,
      method: `POST`,
      data: data,
    });
  },
  update: (data) => {
    return api({
      url: `/business/testimonial/update`,
      method: `PATCH`,
      data: data,
    });
  },
  imageUpload: (data) => {
    return api({
      url: `/business/testimonial/image/upload`,
      method: `POST`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  videoUpload: (data) => {
    return api({
      url: `/business/testimonial/video/upload`,
      method: `POST`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  videoDelete: (data) => {
    return api({
      url: `/business/testimonial/video/delete`,
      method: `POST`,
      data: data,
    });
  },
  delete: (data) => {
    return api({
      url: `/business/testimonial/delete`,
      method: `DELETE`,
      data: data,
    });
  },
};
