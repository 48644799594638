import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Modals
import DimensionsModal from './dimensions';
import ImagesModal from './images';
import TimelineModal from './timeline';

const CaseStudy = ({ session, modal, data }) => {
  return (
    <Fragment>
      {modal === process.env.REACT_APP_CASE_STUDY_DIMENSION_MODAL ? (
        <DimensionsModal {...data}></DimensionsModal>
      ) : modal === process.env.REACT_APP_CASE_STUDY_TIMELINE_MODAL ? (
        <TimelineModal {...data} />
      ) : (
        <ImagesModal session={session} {...data} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

CaseStudy.propTypes = {
  session: PropTypes.object,
  modal: PropTypes.string,
  data: PropTypes.object,
};

export default connect(mapStateToProps, null)(CaseStudy);
