import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Modals
import QuantityModal from './quantity';
import ColorModal from './color';
import ImagesModal from './images';

const Others = ({ session, modal, data }) => {
  return (
    <Fragment>
      {modal === process.env.REACT_APP_OTHERS_QUANTITY_MODAL ? (
        <QuantityModal {...data}></QuantityModal>
      ) : modal === process.env.REACT_APP_OTHERS_COLOR_MODAL ? (
        <ColorModal {...data} />
      ) : (
        <ImagesModal session={session} {...data} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

Others.propTypes = {
  session: PropTypes.object,
  modal: PropTypes.string,
  data: PropTypes.object,
};

export default connect(mapStateToProps, null)(Others);
