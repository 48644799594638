import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { firebaseAnalytics, firebasePerformance } from './services/firebase';
import SwiperCore, { Navigation, Pagination, Thumbs, EffectFade } from 'swiper/core';
import store from './redux/store';

// App Container
import App from './container/app';

// Third Party Styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'swiper/components/effect-fade/effect-fade.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'locomotive-scroll/dist/locomotive-scroll.css';
import 'swiper/components/thumbs/thumbs.min.css';
import 'react-image-crop/dist/ReactCrop.css';
import 'react-quill/dist/quill.snow.css';
import 'filepond/dist/filepond.min.css';
import 'react-tippy/dist/tippy.css';
import 'swiper/swiper.min.css';
import 'rodal/lib/rodal.css';
import 'reset-css';

// Styles
import './styles/scss/main.scss';
import './styles/index.css';

// Dotenv Config
require('dotenv-flow').config();

// Swiper
SwiperCore.use([Navigation, Pagination, Thumbs, EffectFade]);

// Initialize Firebase
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  firebaseAnalytics();
  firebasePerformance();
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
