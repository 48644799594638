/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useBus, { dispatch } from 'use-bus';
import HttpStatusCodes from 'http-status-codes';
import Masonry from 'react-masonry-css';
import PropTypes from 'prop-types';

// Components
import TopProgressBar from '../../../components/loader/topProgressBar';
import MaterialLoader from '../../../components/loader/materialLoader';
import ArchitectureTile from '../../../components/custom/business/architecture/tile';

// Actions
import { notificationSuccess, notificationError } from '../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../services/firebase';

// API Services
import CaseStudyAPI from '../../../services/api/business/caseStudy';

const Highlights = ({ notificationSuccess, notificationError }) => {
  const history = useHistory();

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    highlight(true);
  }, []);

  useBus(
    process.env.REACT_APP_HIGHLIGHT_LIST_REFRESH,
    () => {
      highlight();
    },
    []
  );

  // API Calls
  const highlight = async (loader) => {
    if (loader) {
      setLoading(true);
    }

    try {
      const response = await CaseStudyAPI.highlight();
      const result = response.data;
      setLoading(false);
      setList(result);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          highlight();
        } else {
          notificationError(message);
        }
      }
    }
  };

  const highlightDelete = async (data) => {
    setDeleting(true);

    try {
      const response = await CaseStudyAPI.highlightDelete(data);
      const { message } = response.data;

      setDeleting(false);
      notificationSuccess(message);
      highlight();
    } catch (error) {
      setDeleting(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          highlightDelete(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="highlight pt-32">
      {deleting && <TopProgressBar />}
      {loading ? (
        <div className="absolute top-0 left-0 flex items-center w-full h-screen">
          <MaterialLoader color="black" size="large" />
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 mb-5 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              onClick={() => history.goBack()}
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path>
            </svg>
          </div>
          {list.length < 3 && (
            <div className="row">
              <div className="w-full h-12 flex items-center justify-between relative mb-4">
                <button
                  className={
                    'border-0 py-3 px-3 shadow-md bg-black text-white text-sm font-semibold opacity-100 cursor-pointer'
                  }
                  onClick={() =>
                    dispatch({
                      type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                      payload: {
                        type: process.env.REACT_APP_HIGHLIGHT_MODAL,
                      },
                    })
                  }
                >
                  <div className="flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 mr-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <span>Add New</span>
                  </div>
                </button>
              </div>
            </div>
          )}
          <div className="row">
            <div className="w-full">
              <Masonry
                breakpointCols={{
                  default: 3,
                  1024: 3,
                  768: 2,
                  425: 1,
                }}
                className="masonry-grid"
                columnClassName="masonry-grid-column"
              >
                {list.map((item, index) => (
                  <div key={index}>
                    <ArchitectureTile
                      case={item.case}
                      name={item.name}
                      image={item.image}
                      link={'/case-study/details/'}
                    ></ArchitectureTile>
                    {list.length > 1 && (
                      <p
                        className="text-sm font-semibold underline cursor-pointer"
                        role="presentation"
                        onClick={() =>
                          highlightDelete({
                            id: item.case,
                          })
                        }
                      >
                        Delete
                      </p>
                    )}
                  </div>
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Highlights.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Highlights);
