/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import moment from 'moment';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Schema
import UpdateSchema from './data/schema.json';

// Fields
import Dropdown from '../../../fields/dropdown';
import LoadingButton from '../../../button/loadingButton';

// Actions
import { notificationSuccess, notificationError } from '../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../services/firebase';

// API Services
import OrderAPI from '../../../../services/api/business/order';

// Options
const statusList = [
  {
    id: 'ordered',
    name: 'Ordered',
  },
  {
    id: 'packed',
    name: 'Packed',
  },
  {
    id: 'shipped',
    name: 'Shipped',
  },
  {
    id: 'delivered',
    name: 'Delivered',
  },
];

// Form Validation
const updateSchema = yup.object().shape({});

const OrderUpdate = ({ orderID, id, lastUpdate, field, notificationSuccess, notificationError }) => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      update: null,
    },
    resolver: yupResolver(updateSchema),
  });

  useEffect(() => {
    setValue('update', {
      name: lastUpdate.status,
    });
  }, []);

  const onSubmit = (data) => {
    setLoading(true);

    orderUpdate({
      orderID,
      id,
      status: data.update.name,
      field,
    });
  };

  // API Calls
  const orderUpdate = async (data) => {
    try {
      const response = await OrderAPI.update(data);
      const { message } = response.data;

      setLoading(false);
      notificationSuccess(message);
      dispatch(process.env.REACT_APP_ORDER_DETAILS_REFRESH);
      dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          orderUpdate(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="pt-5 pb-3 relative">
      <div className="absolute" style={{ right: '15px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE)}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
      <h1 className="text-xl text-gray-800 text-center mb-4 font-semibold">Order Update</h1>
      <div className="my-3">
        <p className="text-base font-semibold">Last Update</p>
        <p className="text-sm">Status: {lastUpdate.status}</p>
        <p className="text-sm">Time: {moment(lastUpdate.timestamp).format('DD/MM/YYYY')}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
        {UpdateSchema.map((item, index) => {
          return (
            <div key={index} className="mb-4">
              <Controller
                name={item.id}
                control={control}
                render={({ value, onChange }) => {
                  return (
                    <Dropdown
                      label={item.label}
                      name={item.id}
                      options={statusList}
                      value={value}
                      required={item.required}
                      onChange={(value) => onChange(value)}
                    ></Dropdown>
                  );
                }}
              ></Controller>
            </div>
          );
        })}
        <LoadingButton label={'Update'} fullWidth loading={loading} disabled={loading}></LoadingButton>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

OrderUpdate.propTypes = {
  orderID: PropTypes.string,
  id: PropTypes.string,
  lastUpdate: PropTypes.object,
  field: PropTypes.string,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(OrderUpdate);
