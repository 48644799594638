import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Schema
import SizeSchema from './data/schema.json';

// Fields
import Input from '../../../fields/input';
import Digit from '../../../fields/digit';
import Decimal from '../../../fields/decimal';
import LoadingButton from '../../../button/loadingButton';

// Actions
import { notificationSuccess, notificationError } from '../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../services/firebase';

// API Services
import FurnitureAPI from '../../../../services/api/business/furniture';

// Strings
import { ui } from '../../../../strings/list.json';

// Form Validation
const sizeSchema = yup.object().shape({
  name: yup.string().required(),
  weight: yup.string().required(),
  price: yup.string().required(),
});

const Size = ({ edit, id, size, notificationSuccess, notificationError }) => {
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      name: size ? size.name : '',
      weight: size ? size.weight : 0,
      price: size ? size.price : 0,
    },
    resolver: yupResolver(sizeSchema),
  });

  const onSubmit = (data) => {
    setLoading(true);

    const object = {
      name: data.name,
      weight: Number(data.weight),
      price: Number(data.price),
    };

    if (edit) {
      object.id = size.id;

      updateSize({
        _id: id,
        size: object,
      });
    } else {
      createSize({
        _id: id,
        size: object,
      });
    }
  };

  // API Calls
  const createSize = async (data) => {
    try {
      const response = await FurnitureAPI.sizeCreate(data);
      const { message } = response.data;

      setLoading(false);
      notificationSuccess(message);
      dispatch(process.env.REACT_APP_FURNITURE_REFRESH);
      dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          createSize(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const updateSize = async (data) => {
    try {
      const response = await FurnitureAPI.sizeUpdate(data);
      const { message } = response.data;

      setLoading(false);
      notificationSuccess(message);
      dispatch(process.env.REACT_APP_FURNITURE_REFRESH);
      dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          updateSize(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="pt-5 pb-3 relative">
      <div className="absolute" style={{ right: '15px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE)}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
      <h1 className="text-xl text-gray-800 text-center mb-4 font-semibold">Size</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        {SizeSchema.map((item, index) => {
          const error = ui.errors[errors[item.id]?.type] || errors[item.id]?.message;

          return (
            <div key={index} className="mb-4">
              <Controller
                name={item.id}
                control={control}
                render={({ value, onChange }) =>
                  item.field === 'input' ? (
                    <Input
                      id={item.id}
                      label={item.label}
                      placeholder={item.placeholder}
                      type={item.type}
                      value={value}
                      error={error}
                      required={item.required}
                      onChange={onChange}
                    ></Input>
                  ) : item.field === 'number' ? (
                    <Digit
                      id={item.id}
                      label={item.label}
                      placeholder={item.placeholder}
                      value={value}
                      mask={item.mask}
                      error={error}
                      onChange={onChange}
                      required={item.required}
                    ></Digit>
                  ) : item.field === 'decimal' ? (
                    <Decimal
                      id={item.id}
                      label={item.label}
                      placeholder={item.placeholder}
                      value={value}
                      error={error}
                      onChange={onChange}
                      required={item.required}
                    ></Decimal>
                  ) : null
                }
              ></Controller>
            </div>
          );
        })}
        <LoadingButton label={edit ? 'Update' : 'Add'} fullWidth loading={loading} disabled={loading}></LoadingButton>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Size.propTypes = {
  edit: PropTypes.bool,
  id: PropTypes.string,
  size: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Size);
