import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Schema
import SizeSchema from './data/schema.json';

// Fields
import Input from '../input';
import Digit from '../digit';
import Decimal from '../decimal';
import LoadingButton from '../../button/loadingButton';

// Strings
import { ui } from '../../../strings/list.json';

// Form Validation
const sizeSchema = yup.object().shape({
  name: yup.string().required(),
  weight: yup.string().required(),
  price: yup.string().required(),
});

const Sizes = ({ label, name, value, onChange }) => {
  const [add, setAdd] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, trigger, getValues, errors, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      weight: 0,
      price: 0,
    },
    resolver: yupResolver(sizeSchema),
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  const onSubmit = async () => {
    const valid = await trigger();

    if (valid) {
      const data = getValues();

      const newSizes = [...value];
      newSizes.push({
        name: data.name,
        price: Number(data.price),
        weight: Number(data.weight),
      });
      onChange(newSizes);
      reset();
      setAdd(false);
    }
  };

  return (
    <div className="relative">
      <label
        htmlFor={name}
        className={classNames('flex items-center justify-between text-sm font-bold text-gray-800 mb-2')}
      >
        <p>{label}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => setAdd((add) => !add)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d={
              add
                ? 'M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
                : 'M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'
            }
          ></path>
        </svg>
      </label>
      {value.map((item, index) => (
        <div
          key={index}
          className={classNames(
            'flex items-center justify-between bg-white border border-gray-200 border-solid shadow-md rounded-md text-sm p-2',
            index < value.length - 1 && 'mb-2'
          )}
        >
          <p>
            Name: <span className="font-semibold">{item.name}</span>, Price:{' '}
            <span className="font-semibold">{item.price}</span>, Weight (KG):{' '}
            <span className="font-semibold">{item.weight}</span>
          </p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 cursor-pointer"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            onClick={() => {
              const newSizes = [...value];
              newSizes.splice(index, 1);
              onChange(newSizes);
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            ></path>
          </svg>
        </div>
      ))}
      {add && (
        <div className="pt-3 px-3">
          {SizeSchema.map((item, index) => {
            const error = ui.errors[errors[item.id]?.type] || errors[item.id]?.message;

            return (
              <div key={index} className="mb-4">
                <Controller
                  name={item.id}
                  control={control}
                  render={({ value, onChange }) =>
                    item.field === 'input' ? (
                      <Input
                        id={item.id}
                        label={item.label}
                        placeholder={item.placeholder}
                        type={item.type}
                        value={value}
                        error={error}
                        required={item.required}
                        onChange={onChange}
                      ></Input>
                    ) : item.field === 'number' ? (
                      <Digit
                        id={item.id}
                        label={item.label}
                        placeholder={item.placeholder}
                        value={value}
                        mask={item.mask}
                        error={error}
                        onChange={onChange}
                        required={item.required}
                      ></Digit>
                    ) : item.field === 'decimal' ? (
                      <Decimal
                        id={item.id}
                        label={item.label}
                        placeholder={item.placeholder}
                        value={value}
                        error={error}
                        onChange={onChange}
                        required={item.required}
                      ></Decimal>
                    ) : null
                  }
                ></Controller>
              </div>
            );
          })}
          <LoadingButton
            type="button"
            label={'Update'}
            fullWidth
            loading={loading}
            disabled={loading}
            onClick={() => onSubmit()}
          ></LoadingButton>
        </div>
      )}
    </div>
  );
};

Sizes.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default Sizes;
