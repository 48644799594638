import 'axios-debug-log';
import axios from 'axios';

// Redux Store
import store from '../../redux/store';

const instance = (options) => {
  const {
    session: { idToken },
  } = store.getState();

  const baseURL = process.env.REACT_APP_API_URL;

  const onSuccess = (response) => {
    return response;
  };

  const onError = (error) => {
    return Promise.reject(error.response || error.message);
  };

  const client = axios.create({
    baseURL: baseURL,
    withCredentials: true,
  });

  if (idToken) {
    client.defaults.headers['Authorization'] = `Bearer ${idToken}`;
  }

  return client(options).then(onSuccess).catch(onError);
};

export default instance;
