import api from '../../axios';

export default {
  list: () => {
    return api({
      url: `/business/furniture`,
      method: `GET`,
    });
  },
  inactive: () => {
    return api({
      url: `/business/furniture/inactive`,
      method: `GET`,
    });
  },
  search: (term, active) => {
    return api({
      url: `/business/furniture/search`,
      method: `GET`,
      params: { term, active },
    });
  },
  create: (data) => {
    return api({
      url: `/business/furniture/create`,
      method: `POST`,
      data: data,
    });
  },
  details: (id) => {
    return api({
      url: `/business/furniture/details/${id}`,
      method: `GET`,
    });
  },
  thumbnailUpload: (data) => {
    return api({
      url: `/business/furniture/thumbnail/upload`,
      method: `POST`,
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  dimensionCreate: (data) => {
    return api({
      url: `/business/furniture/dimension/create`,
      method: `POST`,
      data: data,
    });
  },
  dimensionUpdate: (data) => {
    return api({
      url: `/business/furniture/dimension/update`,
      method: `POST`,
      data: data,
    });
  },
  dimensionDelete: (data) => {
    return api({
      url: `/business/furniture/dimension/delete`,
      method: `POST`,
      data: data,
    });
  },
  sizeCreate: (data) => {
    return api({
      url: `/business/furniture/size/create`,
      method: `POST`,
      data: data,
    });
  },
  sizeUpdate: (data) => {
    return api({
      url: `/business/furniture/size/update`,
      method: `POST`,
      data: data,
    });
  },
  sizeDelete: (data) => {
    return api({
      url: `/business/furniture/size/delete`,
      method: `POST`,
      data: data,
    });
  },
  materialCreate: (data) => {
    return api({
      url: `/business/furniture/material/create`,
      method: `POST`,
      data: data,
    });
  },
  materialUpdate: (data) => {
    return api({
      url: `/business/furniture/material/update`,
      method: `POST`,
      data: data,
    });
  },
  materialDelete: (data) => {
    return api({
      url: `/business/furniture/material/delete`,
      method: `POST`,
      data: data,
    });
  },
  colorCreate: (data) => {
    return api({
      url: `/business/furniture/color/create`,
      method: `POST`,
      data: data,
    });
  },
  colorUpdate: (data) => {
    return api({
      url: `/business/furniture/color/update`,
      method: `POST`,
      data: data,
    });
  },
  colorDelete: (data) => {
    return api({
      url: `/business/furniture/color/delete`,
      method: `POST`,
      data: data,
    });
  },
  imageDelete: (data) => {
    return api({
      url: `/business/furniture/image/delete`,
      method: `POST`,
      data: data,
    });
  },
  update: (data) => {
    return api({
      url: `/business/furniture/update`,
      method: `PATCH`,
      data: data,
    });
  },
};
