import React, { Fragment } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';

// Helper HOC
import ScrollToTop from '../hoc/ScrollToTop';

// Routes
import PrivateRoute from '../hoc/routes/privateRoute';
import PublicRoute from '../hoc/routes/publicRoute';
import routes from './routes';

const withLayout = (WrappedComponent) => {
  return function Route() {
    return (
      <Fragment>
        <WrappedComponent></WrappedComponent>
      </Fragment>
    );
  };
};

const Main = () => {
  const location = useLocation();

  return (
    <ScrollToTop>
      <Switch location={location}>
        {routes.map((route, index) =>
          route.isPublic ? (
            <PublicRoute
              key={index}
              exact
              path={route.path}
              restricted={route.restricted}
              component={withLayout(route.component)}
            ></PublicRoute>
          ) : (
            <PrivateRoute key={index} exact path={route.path} component={withLayout(route.component)}></PrivateRoute>
          )
        )}
        <Redirect to="/login" />
      </Switch>
    </ScrollToTop>
  );
};

export default Main;
