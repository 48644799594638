import React from 'react';
import SVG from 'react-inlinesvg';
import Avatars from '@dicebear/avatars';
import sprites from '@dicebear/avatars-initials-sprites';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Components
import ProgressiveImage from '../../custom/progressiveImage';

const Avatar = ({ name, thumbnail, url, size, showArrow }) => {
  const options = {};
  const avatars = new Avatars(sprites, options);
  const svg = avatars.create(name.toLowerCase().replace(' ', '-'));

  return (
    <div className="relative cursor-pointer">
      <button className="relative flex items-center outline-none" onClick={() => {}}>
        {url ? (
          <ProgressiveImage
            preview={thumbnail}
            src={url}
            render={(src, style) => (
              <img
                className={classNames(
                  'bg-white rounded-full',
                  size === 'small' ? 'w-8 h-8' : size === 'medium' ? 'w-10 h-10' : 'w-24 h-24'
                )}
                src={src}
                style={style}
                alt={name}
              />
            )}
          ></ProgressiveImage>
        ) : (
          <SVG
            src={svg}
            className={classNames(
              'bg-black rounded-full',
              size === 'small' ? 'w-8 h-8' : size === 'medium' ? 'w-10 h-10' : 'w-24 h-24'
            )}
          />
        )}
        {showArrow && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 stroke-current text-black"
            fill="currentColor"
            viewBox="0 0 20 20"
            style={{ marginLeft: '1px' }}
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        )}
      </button>
    </div>
  );
};

Avatar.defaultProps = {
  size: 'medium',
  showArrow: true,
};

Avatar.propTypes = {
  name: PropTypes.string,
  thumbnail: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  showArrow: PropTypes.bool,
};

export default Avatar;
