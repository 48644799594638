/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useRef, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import useBus, { dispatch } from 'use-bus';
import Rodal from 'rodal';
import PropTypes from 'prop-types';

// Actions
import { sessionActive, sessionInactive } from '../redux/root/actions';

// Firebase Auth Services
import { onIdTokenChanged, signOut } from '../services/firebase/auth';

// Components
import Header from '../components/navigation/header';
import Content from '../content';
import TabletNavigation from '../components/tablet/navigation';

// Modals
import TeamModal from '../components/modals/team';
import HighlightModal from '../components/modals/highlight';
import TestimonialModal from '../components/modals/testimonial';
import CaseStudyModal from '../components/modals/caseStudy';
import ArtModal from '../components/modals/art';
import FurnitureModal from '../components/modals/furniture';
import OthersModal from '../components/modals/others';
import Shipping from '../components/modals/custom/shipping';
import OrderUpdate from '../components/modals/order/update';
import ImageCrop from '../components/modals/custom/imageCrop';

// Title
import titles from '../strings/title.json';

const AppLayout = ({ children, sessionActive, sessionInactive }) => {
  const containerRef = useRef(null);

  const location = useLocation();

  const [title, setTitle] = useState(null);
  const [modal, setModal] = useState({
    type: null,
    layout: null,
    data: null,
  });
  const [visible, setVisible] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 425 });

  useEffect(() => {
    tokenChanged();
  }, []);

  useEffect(() => {
    const title = `${titles.prefix} ${titles.page[location.pathname] || 'Loading...'}`;

    setTitle(title);
  }, [location]);

  useBus(
    process.env.REACT_APP_CHANGE_PAGE_TITLE,
    ({ payload }) => {
      const { title } = payload;
      setTitle(`Banter Niche Designs - ${title}`);
    },
    []
  );

  useBus(
    process.env.REACT_APP_LAYOUT_MODAL_OPEN,
    ({ payload }) => {
      const { type, layout, data } = payload;
      setModal({
        type,
        layout,
        data,
      });

      setVisible(true);
    },
    [visible]
  );

  useBus(
    process.env.REACT_APP_LAYOUT_MODAL_CLOSE,
    () => {
      setVisible(false);

      setTimeout(() => {
        setModal({
          type: null,
          layout: null,
          data: null,
        });
      }, Number(process.env.REACT_APP_RODAL_DURATION));
    },
    [visible]
  );

  useBus(
    process.env.REACT_APP_LOG_USER_OUT,
    async () => {
      await signOut();
    },
    []
  );

  const tokenChanged = useCallback(async () => {
    await onIdTokenChanged(async (user) => {
      if (user) {
        const idToken = await user.getIdToken();

        const { uid, displayName, email, phoneNumber, photoURL, emailVerified } = user.toJSON();

        sessionActive(idToken, {
          uid,
          displayName,
          email,
          phoneNumber,
          photoURL,
          emailVerified,
        });
      } else {
        sessionInactive();
      }
    });
  }, [sessionActive, sessionInactive]);

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header />
      <TabletNavigation close={() => dispatch(process.env.REACT_APP_TAB_NAV_CLOSE)} />
      <LocomotiveScrollProvider
        options={{
          smooth: true,
          tablet: {
            smooth: true,
          },
          smartphone: {
            smooth: true,
          },
        }}
        watch={['.app']}
        containerRef={containerRef}
      >
        <div className="main-content" data-scroll-container ref={containerRef}>
          <Content>{children}</Content>
        </div>
      </LocomotiveScrollProvider>
      <Rodal
        className="layout-rodal"
        visible={visible}
        customStyles={{
          width: !isMobile ? '360px' : 'calc(100% - 20px)',
          height: isMobile ? '100%' : 'auto',
          bottom: 'auto',
          top: 'auto',
          overflow: 'auto',
        }}
        animation={process.env.REACT_APP_RODAL_ANIMATION}
        duration={Number(process.env.REACT_APP_RODAL_DURATION)}
        closeOnEsc={false}
        closeMaskOnClick={true}
        showCloseButton={false}
        onClose={() => setVisible((visible) => !visible)}
      >
        <div className="p-4">
          {modal.type === process.env.REACT_APP_TEAM_MODAL ? (
            <TeamModal modal={modal.layout} data={modal.data} />
          ) : modal.type === process.env.REACT_APP_HIGHLIGHT_MODAL ? (
            <HighlightModal />
          ) : modal.type === process.env.REACT_APP_TESTIMONIAL_MODAL ? (
            <TestimonialModal modal={modal.layout} data={modal.data} />
          ) : modal.type === process.env.REACT_APP_CASE_STUDY_MODAL ? (
            <CaseStudyModal modal={modal.layout} data={modal.data} />
          ) : modal.type === process.env.REACT_APP_ART_MODAL ? (
            <ArtModal modal={modal.layout} data={modal.data} />
          ) : modal.type === process.env.REACT_APP_FURNITURE_MODAL ? (
            <FurnitureModal modal={modal.layout} data={modal.data} />
          ) : modal.type === process.env.REACT_APP_OTHERS_MODAL ? (
            <OthersModal modal={modal.layout} data={modal.data} />
          ) : modal.type === process.env.REACT_APP_SHIPPING_BREAKDOWN_MODAL ? (
            <Shipping {...modal.data} />
          ) : modal.type === process.env.REACT_APP_ORDER_UPDATE_MODAL ? (
            <OrderUpdate {...modal.data} />
          ) : modal.layout === process.env.REACT_APP_IMAGE_UPLOAD_MODAL ? (
            <ImageCrop {...modal.data} />
          ) : null}
        </div>
      </Rodal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  sessionActive: (idToken, user) => dispatch(sessionActive(idToken, user)),
  sessionInactive: () => dispatch(sessionInactive()),
});

AppLayout.propTypes = {
  children: PropTypes.object,
  sessionActive: PropTypes.func,
  sessionInactive: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
