import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// Modals
import FormModal from './form';
import VideoModal from './video';

const Testimonial = ({ modal, data }) => {
  return (
    <Fragment>
      {modal === process.env.REACT_APP_TESTIMONIAL_CRUD_MODAL ? (
        <FormModal {...data}></FormModal>
      ) : (
        <VideoModal {...data} />
      )}
    </Fragment>
  );
};

Testimonial.propTypes = {
  modal: PropTypes.string,
  data: PropTypes.object,
};

export default Testimonial;
