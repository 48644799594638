import React from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';

const Description = ({ id, label, value, error, showMessage, onChange, disabled, required, ...props }) => {
  return (
    <div className="description-input">
      <div className="flex justify-between items-center mb-1">
        <label htmlFor={id} className="text-sm font-bold text-gray-800 flex items-center">
          {label}{' '}
          {required && (
            <span className="text-red-600" style={{ marginLeft: '2px' }}>
              *
            </span>
          )}
        </label>
        {showMessage && <p className="text-red-600 text-xs text-right font-semibold">{error}</p>}
      </div>
      <ReactQuill value={value} onChange={(content) => onChange(content)}></ReactQuill>
    </div>
  );
};

Description.defaultProps = {
  error: null,
  showMessage: true,
};

Description.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  showMessage: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default Description;
