import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Components
import ProgressiveImage from '../../../progressiveImage';

const Tile = ({ case: id, name, image, link }) => {
  const history = useHistory();

  const { url, thumbnail } = image;

  return (
    <div
      className="tile cursor-pointer"
      role="button"
      tabIndex="0"
      onKeyDown={() => {}}
      onClick={() => history.push(`${link}${id}`)}
    >
      <div style={{ paddingTop: '100%', position: 'relative' }}>
        <div className="thumbnail" style={{ width: '100%', height: '100%', position: 'absolute', top: 0 }}>
          <ProgressiveImage
            preview={thumbnail}
            src={url}
            render={(src, style) => (
              <img className={classNames('w-full h-full object-cover')} src={src} style={style} alt={name} />
            )}
          ></ProgressiveImage>
        </div>
      </div>
      <div className="flex flex-col py-3">
        <span className="font-bold mb-2" style={{ fontSize: '18px' }}>
          {name}
        </span>
        <span className="font-normal" style={{ fontSize: '15px' }}>
          {id}
        </span>
      </div>
    </div>
  );
};

Tile.propTypes = {
  case: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.object,
  link: PropTypes.string,
};

export default Tile;
