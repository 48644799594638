/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Camera } from 'react-feather';
import useBus, { dispatch } from 'use-bus';
import HttpStatusCodes from 'http-status-codes';
import Masonry from 'react-masonry-css';
import PropTypes from 'prop-types';

// Components
import MaterialLoader from '../../../components/loader/materialLoader';

// Actions
import { notificationSuccess, notificationError } from '../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../services/firebase';

// API Services
import TeamAPI from '../../../services/api/business/team';

const Teams = ({ notificationSuccess, notificationError }) => {
  const [teams, setTeams] = useState([]);
  const [uploading, setUploading] = useState({
    index: 0,
    state: false,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    teamList(true);
  }, []);

  useBus(
    process.env.REACT_APP_TEAM_LIST_REFRESH,
    () => {
      teamList();
    },
    []
  );

  useBus(
    process.env.REACT_APP_TEAM_CROP_MODAL,
    ({ payload }) => {
      const { id, index, dataUrl } = payload;

      setUploading({
        index,
        state: true,
      });

      const data = new FormData();
      data.append('id', id);
      data.append('image', dataURLtoFile(dataUrl, 'Image'));

      imageUpload(data);
    },
    [uploading]
  );

  const handleImageChange = (index, id, event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      dispatch({
        type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
        payload: {
          layout: process.env.REACT_APP_IMAGE_UPLOAD_MODAL,
          data: {
            type: process.env.REACT_APP_TEAM_CROP_MODAL,
            id,
            index,
            src: fileReader.result,
          },
        },
      });
    };

    if (file) {
      fileReader.readAsDataURL(file);
    }
  };

  const dataURLtoFile = (dataUrl, filename) => {
    const array = dataUrl.split(',');
    const mime = array[0].match(/:(.*?);/)[1];
    const blobString = atob(array[1]);
    let length = blobString.length;
    const uInt8Array = new Uint8Array(length);

    while (length--) {
      uInt8Array[length] = blobString.charCodeAt(length);
    }

    return new File([uInt8Array], filename, { type: mime });
  };

  // API Calls
  const teamList = async (loader) => {
    if (loader) {
      setLoading(true);
    }

    try {
      const response = await TeamAPI.list();
      const result = response.data;
      setTeams(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      }
    }
  };

  const imageUpload = async (data) => {
    try {
      const response = await TeamAPI.imageUpload(data);
      const { message } = response.data;

      setUploading({
        index: 0,
        state: false,
      });
      notificationSuccess(message);
      teamList();
    } catch (error) {
      setUploading({
        index: 0,
        state: false,
      });

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          imageUpload(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const deleteTeam = async (index, id) => {
    setUploading({
      index,
      state: true,
    });

    try {
      const response = await TeamAPI.delete({
        id,
      });
      const { message } = response.data;

      setUploading({
        index: 0,
        state: false,
      });
      notificationSuccess(message);
      teamList();
    } catch (error) {
      setUploading({
        index: 0,
        state: false,
      });

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          deleteTeam(index, id);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="teams pt-32">
      {loading ? (
        <div className="absolute top-0 left-0 flex items-center w-full h-screen">
          <MaterialLoader color="black" size="large" />
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <div className="w-full">
              <div className="flex justify-end w-full mb-4">
                <button
                  className={
                    'border-0 py-3 px-3 shadow-md bg-black text-white text-sm font-semibold opacity-100 cursor-pointer'
                  }
                  onClick={() => {
                    dispatch({
                      type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                      payload: {
                        type: process.env.REACT_APP_TEAM_MODAL,
                        layout: process.env.REACT_APP_TEAM_CRUD_MODAL,
                        data: {
                          edit: false,
                        },
                      },
                    });
                  }}
                >
                  <div className="flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-5 h-5 mr-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <span>Add Member</span>
                  </div>
                </button>
              </div>
              <Masonry
                breakpointCols={{
                  default: 4,
                  1024: 3,
                  768: 2,
                  425: 1,
                }}
                className="masonry-grid"
                columnClassName="masonry-grid-column"
              >
                {teams.map((team, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-start py-3 pl-3 shadow-md rounded-md border border-solid border-gray-200 relative"
                    style={{ height: 'fit-content' }}
                  >
                    {uploading.index === index && uploading.state && (
                      <div className="absolute top-0 right-0" style={{ marginTop: '10px', marginRight: '10px' }}>
                        <MaterialLoader color="black" />
                      </div>
                    )}
                    <div className="cursor-pointer relative">
                      <img
                        className="rounded-full"
                        style={{ width: '50px', height: '50px' }}
                        src={team.image?.url || '/images/static/default-profile.png'}
                        alt={team.name}
                      />
                      <div className="flex items-center justify-center absolute bg-black w-6 h-6 rounded-full cursor-pointer bottom-0 right-0">
                        <input
                          accept="image/*"
                          hidden
                          id={`image-${index}`}
                          type="file"
                          onChange={(event) => handleImageChange(index, team.id, event)}
                        />
                        <label htmlFor={`image-${index}`} type="button">
                          <Camera className="w-3 h-3 text-white cursor-pointer" />
                        </label>
                      </div>
                    </div>
                    <div className="flex flex-col ml-3">
                      <span className="text-base font-bold">{team.name}</span>
                      <span className="text-xs font-medium">/{team.designation}</span>
                      <div className="flex justify-start" style={{ marginTop: '10px', fontSize: '13px' }}>
                        <span
                          className="underline cursor-pointer"
                          role="presentation"
                          onClick={() => {
                            dispatch({
                              type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                              payload: {
                                type: process.env.REACT_APP_TEAM_MODAL,
                                layout: process.env.REACT_APP_TEAM_CRUD_MODAL,
                                data: {
                                  edit: true,
                                  member: {
                                    id: team.id,
                                    name: team.name,
                                    designation: team.designation,
                                    description: team.description,
                                    facebook: team.facebook,
                                    twitter: team.twitter,
                                    instagram: team.instagram,
                                    linkedin: team.linkedin,
                                  },
                                },
                              },
                            });
                          }}
                        >
                          Edit
                        </span>
                        <span
                          className="underline cursor-pointer ml-2"
                          role="presentation"
                          onClick={() => {
                            dispatch({
                              type: process.env.REACT_APP_LAYOUT_MODAL_OPEN,
                              payload: {
                                type: process.env.REACT_APP_TEAM_MODAL,
                                layout: process.env.REACT_APP_TEAM_VIDEO_MODAL,
                                data: {
                                  id: team.id,
                                  video: team.video,
                                },
                              },
                            });
                          }}
                        >
                          Video
                        </span>
                        {teams.length > 1 && (
                          <span
                            className="underline cursor-pointer ml-2"
                            role="presentation"
                            onClick={() => deleteTeam(index, team.id)}
                          >
                            Delete
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Masonry>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Teams.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
