import React, { useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import calendar, {
  isDate,
  isSameDay,
  isSameMonth,
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  getYears,
  getRoundedYear,
  WEEK_DAYS,
  CALENDAR_MONTHS,
} from '../helpers/calendar';
import {
  ArrowLeft,
  ArrowRight,
  CalendarContainer,
  CalendarHeader,
  CalendarMonth,
  CalendarGrid,
  CalendarDay,
  CalendarDate,
  HighlightedCalendarDate,
  TodayCalendarDate,
  YearGrid,
  YearCell,
  MonthGrid,
  MonthCell,
  Item,
  HighlightedItem,
} from './style';
import PropTypes from 'prop-types';

// Timeout
const YEAR_THRESHOLD = moment().year() - 100;
const today = new Date();
let pressureTimeout;
let pressureTimer;
let dayTimeout;

const Calendar = ({ date, onDateChanged, backward, forward, events, disableFuture, disablePast }) => {
  const [current, setCurrent] = useState(date.toDate());
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(YEAR_THRESHOLD);
  const [mode, setMode] = useState(0);
  const [endYear, setEndYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setMonth(date.month() + 1);
    setYear(date.year());
    setEndYear(getRoundedYear(date.year(), 10));

    return () => {
      clearPressureTimer();
      clearDayTimeout();
    };
  }, [date]);

  const resolveStateFromDate = (date) => {
    const isDateObject = isDate(date);
    const _date = isDateObject ? date : new Date();

    return {
      current: isDateObject ? date : null,
      month: +_date.getMonth() + 1,
      year: _date.getFullYear(),
    };
  };

  const renderCalendarHeader = () => {
    const monthName = Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))];

    const current = moment(`${monthName}/${year}`, 'MMMM/YYYY');

    return (
      <CalendarHeader>
        <ArrowLeft
          onMouseDown={disablePast ? (current.isBefore(moment()) ? null : handlePrevious) : handlePrevious}
          onMouseUp={clearPressureTimer}
        >
          <svg viewBox="0 0 20 20" fill="currentColor" className="arrow-left w-4 h-4 text-black cursor-pointer">
            <path
              fillRule="evenodd"
              d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        </ArrowLeft>
        <CalendarMonth onClick={() => setMode((mode) => (mode === 0 ? 1 : mode === 1 ? 2 : 0))}>
          <div className="flex items-center">
            {mode === 0 ? (
              <span>
                {monthName} {year}
              </span>
            ) : mode === 1 ? (
              <span>
                {endYear - 9} - {endYear}
              </span>
            ) : (
              <span>{year}</span>
            )}
            <svg viewBox="0 0 20 20" fill="currentColor" className="arrow-circle-down w-6 h-6 ml-1">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </CalendarMonth>
        <ArrowRight
          onMouseDown={disableFuture ? (current.diff(moment(), 'month') + 1 > 0 ? null : handleNext) : handleNext}
          onMouseUp={clearPressureTimer}
        >
          <svg viewBox="0 0 20 20" fill="currentColor" className="arrow-right w-4 h-4 text-black cursor-pointer">
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </ArrowRight>
      </CalendarHeader>
    );
  };

  const renderDayLabel = (day, index) => {
    const daylabel = WEEK_DAYS[day];

    return (
      <CalendarDay key={index} index={index}>
        {daylabel}
      </CalendarDay>
    );
  };

  const renderCalendarDate = (date, index) => {
    const _date = new Date(date.join('-'));

    const isToday = isSameDay(_date, today);
    const isCurrent = current && isSameDay(_date, current);
    const inMonth = month && year && isSameMonth(_date, new Date([year, month, 1].join('-')));

    const onClick = gotoDate(_date);

    const props = { index, inMonth, onClick };

    const DateComponent = isCurrent ? HighlightedCalendarDate : isToday ? TodayCalendarDate : CalendarDate;

    const event = events.find((event) => event.day === Number(moment(_date).format('D'))) || { available: true };

    return (
      <DateComponent
        key={getDateISO(_date)}
        {...props}
        className={classNames(
          'flex items-center justify-center',
          disablePast ? (moment().isAfter(moment(_date).add(1, 'day')) ? 'date-disabled' : null) : null,
          disableFuture ? (moment(_date).isBefore(moment().endOf('month')) ? null : 'date-disabled') : null,
          !event?.available && 'date-disabled'
        )}
      >
        <p>{_date.getDate()}</p>
      </DateComponent>
    );
  };

  const renderYearLabel = () => {
    const years = getYears(10, endYear);

    return years.map((item, index) => {
      const YearComponent = item === year ? HighlightedItem : Item;

      return (
        <YearCell key={index} index={index}>
          <YearComponent
            onClick={() => {
              setYear(item);
              setMode(2);
            }}
          >
            {item}
          </YearComponent>
        </YearCell>
      );
    });
  };

  const renderMonthLabel = () => {
    return Object.values(CALENDAR_MONTHS).map((item, index) => {
      const MonthComponent = index === month - 1 ? HighlightedItem : Item;

      return (
        <MonthCell key={index} index={index}>
          <MonthComponent
            onClick={() => {
              setMonth(index + 1);
              setMode(0);
            }}
          >
            {item}
          </MonthComponent>
        </MonthCell>
      );
    });
  };

  const getCalendarDates = () => {
    const calendarMonth = month || current.month() + 1;
    const calendarYear = year || current.year();

    return calendar(calendarMonth, calendarYear);
  };

  const handlePressure = (func) => {
    if (typeof func === 'function') {
      func();
      pressureTimeout = setTimeout(() => {
        pressureTimer = setInterval(func, 100);
      }, 500);
    }
  };

  const clearPressureTimer = () => {
    pressureTimer && clearInterval(pressureTimer);
    pressureTimeout && clearTimeout(pressureTimeout);
  };

  const clearDayTimeout = () => {
    dayTimeout && clearTimeout(dayTimeout);
  };

  const handlePrevious = (event) => {
    event && event.preventDefault();
    const func =
      mode === 0 ? (event.shiftKey ? gotoPreviousYear : gotoPreviousMonth) : mode === 1 ? gotoPreviousYearRange : null;
    backward(month, year);
    handlePressure(func);
  };

  const handleNext = (event) => {
    event && event.preventDefault();
    const func = mode === 0 ? (event.shiftKey ? gotoNextYear : gotoNextMonth) : mode === 1 ? gotoNextYearRange : null;
    forward(month, year);
    handlePressure(func);
  };

  const gotoDate = (date) => (event) => {
    event && event.preventDefault();

    if (!(current && isSameDay(date, current))) {
      const newDate = resolveStateFromDate(date);
      setCurrent(newDate.current);
      setMonth(newDate.month);
      setYear(newDate.year);
      typeof onDateChanged === 'function' && onDateChanged(date);
    }
  };

  const gotoPreviousMonth = () => {
    const newMonth = getPreviousMonth(month, year);
    setMonth(newMonth.month);
    setYear(newMonth.year);
  };

  const gotoNextMonth = () => {
    const newMonth = getNextMonth(month, year);
    setMonth(newMonth.month);
    setYear(newMonth.year);
  };

  const gotoPreviousYear = () => {
    setYear((year) => year - 1);
  };

  const gotoNextYear = () => {
    setYear((year) => year + 1);
  };

  const gotoPreviousYearRange = () => {
    if (endYear > YEAR_THRESHOLD + 10) {
      setEndYear(endYear - 10);
    }
  };

  const gotoNextYearRange = () => {
    if (endYear < new Date().getFullYear() + 100) {
      setEndYear(endYear + 10);
    }
  };

  return (
    <CalendarContainer>
      {renderCalendarHeader()}
      {mode === 0 ? (
        <CalendarGrid>
          {Object.keys(WEEK_DAYS).map(renderDayLabel)}
          {getCalendarDates().map(renderCalendarDate)}
        </CalendarGrid>
      ) : mode === 1 ? (
        <YearGrid>{renderYearLabel()}</YearGrid>
      ) : (
        <MonthGrid>{renderMonthLabel()}</MonthGrid>
      )}
    </CalendarContainer>
  );
};

Calendar.propTypes = {
  date: PropTypes.object,
  onDateChanged: PropTypes.func,
  backward: PropTypes.func,
  forward: PropTypes.func,
  isPortal: PropTypes.bool,
  events: PropTypes.array,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
};

export default Calendar;
