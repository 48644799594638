import React, { useState, useMemo } from 'react';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';

const Dropdown = ({ label, placeholder, value, options, error, onChange, required }) => {
  const [showOptions, setOptionsVisibility] = useState(false);
  const [selectElement, setSelectElement] = useState(null);
  const [optionsElement, setOptionsElement] = useState(null);

  const modifiers = useMemo(
    () => [
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'sameWidth',
        enabled: true,
        phase: 'beforeWrite',
        requires: ['computeStyles'],
        fn({ state }) {
          state.styles.popper.width = `${state.rects.reference.width}px`;
        },
        effect({ state }) {
          state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
        },
      },
    ],
    []
  );

  const { styles, attributes } = usePopper(selectElement, optionsElement, {
    modifiers: modifiers,
    strategy: 'fixed',
  });

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOptionsVisibility(false);
      }}
    >
      {label && (
        <div className="flex justify-between items-center mb-2">
          <label htmlFor="dropdown" className="text-sm font-bold text-gray-800 flex items-center">
            {label || 'Select'}{' '}
            {required && (
              <span className="text-red-600" style={{ marginLeft: '2px' }}>
                *
              </span>
            )}
          </label>
          <p className="text-red-600 text-xs text-right font-semibold">{error}</p>
        </div>
      )}
      <div className="dropdown flex flex-col">
        {showOptions ? (
          <div
            ref={setOptionsElement}
            style={{ zIndex: 1, ...styles.popper }}
            className="options-container shadow-md border border-solid border-gray-100"
            {...attributes.popper}
          >
            <div
              style={{
                maxHeight: '270px',
              }}
              className="bg-white shadow-700 rounded overflow-hidden order-1 overflow-y-auto text-sm"
            >
              {options.map((item, index) => (
                <div
                  key={index}
                  className={classNames(
                    'option flex justify-between items-center px-4 py-3 cursor-pointer hover:bg-gray-200',
                    item.disabled ? 'bg-gray-300 cursor-not-allowed pointer-events-none' : null
                  )}
                  onClick={() => {
                    onChange(item);
                    setOptionsVisibility(false);
                  }}
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  <input id={item.id} name={item.name} type="radio" className="radio hidden" />
                  <label htmlFor={item.id} className="cursor-pointer">
                    {item.name}
                  </label>
                  {item.prefix ? (
                    <div className="px-2 py-1 bg-black rounded-full">
                      <span className="text-white text-sm">{item.prefix}</span>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div
          ref={setSelectElement}
          className={classNames(
            'selected rounded p-3 bg-gray-200 border cursor-pointer relative order-none',
            error ? 'border-red-600' : 'border-transparent'
          )}
          onClick={() => setOptionsVisibility((showOptions) => !showOptions)}
          onKeyDown={() => {}}
          role="button"
          tabIndex="0"
        >
          <p className="whitespace-no-wrap overflow-hidden text-sm">{value ? value.name : placeholder}</p>
          <div className="absolute inset-y-0 right-0 pl-2 pr-2 flex items-center text-sm leading-5 bg-gray-200">
            {showOptions ? (
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7"></path>
              </svg>
            ) : (
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  options: PropTypes.array,
  error: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default Dropdown;
