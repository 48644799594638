/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Components
import TopProgressBar from '../../../components/loader/topProgressBar';

// Schema
import ConfigSchema from './data/schema.json';

// Fields
import Input from '../../../components/fields/input';
import Digit from '../../../components/fields/digit';
import Decimal from '../../../components/fields/decimal';
import Textarea from '../../../components/fields/textarea';
import LoadingButton from '../../../components/button/loadingButton';

// Strings
import { ui } from '../../../strings/list.json';

// Actions
import { notificationSuccess, notificationError } from '../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../services/firebase';

// API Services
import ConfigAPI from '../../../services/api/business/config';

// Form Validation
const configSchema = yup.object().shape({
  gstin: yup.string().required(),
  address: yup.string().required(),
});

const Config = ({ notificationSuccess, notificationError }) => {
  const history = useHistory();

  const [id, setID] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const { control, handleSubmit, setValue, errors } = useForm({
    defaultValues: {
      gstin: '',
      phone: 0,
      address: '',
      base: 0,
      distanceCost: 0.0,
      weightCost: 0.0,
    },
    resolver: yupResolver(configSchema),
  });

  useEffect(() => {
    details();
  }, []);

  const onSubmit = async (data) => {
    const { gstin, phone, address, base, distanceCost, weightCost } = data;

    update({
      id,
      gstin,
      phone: Number(phone),
      address,
      base: Number(base),
      distanceCost: Number(distanceCost),
      weightCost: Number(weightCost),
    });
  };

  // API Calls
  const details = async () => {
    setLoading(true);

    try {
      const response = await ConfigAPI.details();
      const result = response.data;

      setLoading(false);

      const { id, gstin, phone, address, base, distanceCost, weightCost } = result;

      setID(id);
      setValue('gstin', gstin);
      setValue('phone', phone);
      setValue('address', address);
      setValue('base', base);
      setValue('distanceCost', distanceCost);
      setValue('weightCost', weightCost);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          details();
        } else {
          notificationError(message);
        }
      }
    }
  };

  const update = async (data) => {
    setUpdating(true);

    try {
      const response = await ConfigAPI.update(data);
      const { message } = response.data;

      setUpdating(false);
      notificationSuccess(message);
    } catch (error) {
      setUpdating(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          update(data);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="config pt-24">
      {loading && <TopProgressBar />}
      <div className="container">
        <div className="row">
          <div className="grid grid-cols-12 gap-6 w-full">
            <div className="col-span-12 sm:col-span-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 mb-5 cursor-pointer"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                onClick={() => history.goBack()}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                ></path>
              </svg>
              <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                {ConfigSchema.map((item, index) => {
                  const error = ui.errors[errors[item.id]?.type] || errors[item.id]?.message || null;

                  return (
                    <div key={index} className="mb-4">
                      <Controller
                        name={item.id}
                        control={control}
                        render={({ value, onChange }) =>
                          item.general ? (
                            <Input
                              id={item.id}
                              label={item.label}
                              placeholder={item.placeholder}
                              value={value}
                              type={item.type}
                              disabled={item.disabled}
                              error={error}
                              onChange={onChange}
                            ></Input>
                          ) : item.field === 'textarea' ? (
                            <Textarea
                              id={item.id}
                              label={item.label}
                              placeholder={item.placeholder}
                              rows={4}
                              value={value}
                              error={error}
                              required={item.required}
                              onChange={onChange}
                            ></Textarea>
                          ) : item.field === 'number' ? (
                            <Digit
                              id={item.id}
                              label={item.label}
                              placeholder={item.placeholder}
                              value={value}
                              mask={item.mask}
                              error={error}
                              onChange={onChange}
                              required={item.required}
                            ></Digit>
                          ) : item.field === 'decimal' ? (
                            <Decimal
                              id={item.id}
                              label={item.label}
                              placeholder={item.placeholder}
                              value={value}
                              error={error}
                              onChange={onChange}
                              required={item.required}
                            ></Decimal>
                          ) : null
                        }
                      ></Controller>
                    </div>
                  );
                })}
                <LoadingButton label={'Update'} fullWidth={true} loading={updating} disabled={updating} />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Config.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Config);
