import api from '../../axios';

export default {
  details: () => {
    return api({
      url: `/business/config/details`,
      method: `GET`,
    });
  },
  update: (data) => {
    return api({
      url: `/business/config/update`,
      method: `PATCH`,
      data: data,
    });
  },
};
