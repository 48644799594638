/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import useBus from 'use-bus';
import PropTypes from 'prop-types';

const Content = ({ children }) => {
  const { scroll } = useLocomotiveScroll();

  const [scroller, setScroller] = useState(false);

  useEffect(() => {
    if (scroll) {
      if (!scroller) {
        scroll.on('scroll', ({ scroll }) => {
          const header = document.querySelector('.header');

          if (scroll.y > Number(process.env.REACT_APP_HEADER_SCROLL_THRESHOLD)) {
            if (header.classList.length === 1) {
              header.classList.add('header--scrolled');
            }
          } else {
            if (header.classList.length > 1) {
              header.classList.remove('header--scrolled');
            }
          }
        });

        setScroller(true);
      }
    }
  }, [scroll]);

  useBus(
    process.env.REACT_APP_SCROLL_TO_TOP,
    () => {
      scroll.scrollTo('top', {
        duration: 0,
      });
    },
    [scroll]
  );

  return <div className="app">{children}</div>;
};

Content.propTypes = {
  children: PropTypes.object,
};

export default Content;
