import api from '../../axios';

export default {
  list: (active) => {
    return api({
      url: `/business/career/list`,
      method: `GET`,
      params: {
        active: active,
      },
    });
  },
  details: (id) => {
    return api({
      url: `/business/career/details/${id}`,
      method: `GET`,
    });
  },
  update: (data) => {
    return api({
      url: `/business/career/update`,
      method: `PATCH`,
      data: data,
    });
  },
};
