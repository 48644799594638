import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Modals
import DimensionsModal from './dimensions';
import SizeModal from './size';
import MaterialModal from './material';
import ColorModal from './color';
import ImagesModal from './images';

const Furniture = ({ session, modal, data }) => {
  return (
    <Fragment>
      {modal === process.env.REACT_APP_FURNITURE_DIMENSION_MODAL ? (
        <DimensionsModal {...data}></DimensionsModal>
      ) : modal === process.env.REACT_APP_FURNITURE_SIZE_MODAL ? (
        <SizeModal {...data}></SizeModal>
      ) : modal === process.env.REACT_APP_FURNITURE_MATERIAL_MODAL ? (
        <MaterialModal {...data} />
      ) : modal === process.env.REACT_APP_FURNITURE_COLOR_MODAL ? (
        <ColorModal {...data} />
      ) : (
        <ImagesModal session={session} {...data} />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

Furniture.propTypes = {
  session: PropTypes.object,
  modal: PropTypes.string,
  data: PropTypes.object,
};

export default connect(mapStateToProps, null)(Furniture);
