/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import MaterialLoader from '../../components/loader/materialLoader';

const PrivateRoute = ({ component: Component, session, ...rest }) => {
  const { loading, logged } = session;

  return loading ? (
    <div className="h-screen bg-white flex justify-center items-center">
      <MaterialLoader color="black" size="large" />
    </div>
  ) : (
    <Route
      {...rest}
      render={(props) =>
        logged ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    ></Route>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  session: PropTypes.object,
};

export default connect(mapStateToProps, null)(PrivateRoute);
