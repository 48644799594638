import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import PropTypes from 'prop-types';

// Actions
import { notificationSuccess, notificationError } from '../../../../redux/root/actions';

// File Upload
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Variables
const fileTypes = ['image/*'];

registerPlugin(FilePondPluginFileRename, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const Images = ({ session, id, gallery }) => {
  const [counter, setCounter] = useState(0);

  return (
    <div className="pt-5 pb-3 relative">
      <div className="absolute" style={{ right: '15px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 cursor-pointer"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          onClick={() => {
            if (counter > 0) {
              dispatch(process.env.REACT_APP_OTHERS_REFRESH);
            }

            dispatch(process.env.REACT_APP_LAYOUT_MODAL_CLOSE);
          }}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </div>
      <h1 className="text-xl text-gray-800 text-center mb-4 font-semibold">
        {gallery === 0 ? 'Images Upload' : 'Gallery Upload'}
      </h1>
      <FilePond
        name="image"
        allowMultiple={true}
        allowFileSizeValidation={true}
        allowFileTypeValidation={true}
        maxFileSize={process.env.REACT_APP_MAX_FILE_SIZE}
        acceptedFileTypes={fileTypes}
        onprocessfile={(error, file) => {
          if (!error) {
            setCounter((counter) => counter + 1);
          }
        }}
        onremovefile={(error, file) => {
          if (!error) {
            setCounter((counter) => counter - 1);
          }
        }}
        server={{
          url: process.env.REACT_APP_API_URL,
          process: {
            url: `/business/paint/image/upload`,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${session.idToken}`,
            },
            ondata: (formData) => {
              formData.append('id', id);
              formData.append('gallery', gallery);
              return formData;
            },
          },
          revert: {
            url: `/business/paint/image/delete`,
            method: 'POST',
            headers: {
              Authorization: `Bearer ${session.idToken}`,
            },
          },
        }}
      ></FilePond>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Images.propTypes = {
  session: PropTypes.object,
  id: PropTypes.string,
  gallery: PropTypes.number,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(Images);
