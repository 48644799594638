import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Styles
import './style.scss';

const MaterialLoader = ({ color, size }) => {
  return (
    <div className={classNames('material-loader', size)}>
      <svg className="circular" viewBox="25 25 50 50">
        <circle
          className={classNames('path')}
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke={`${color || 'white'}`}
          strokeWidth="2"
          strokeMiterlimit="10"
        ></circle>
      </svg>
    </div>
  );
};

MaterialLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'midway', 'medium', 'large']),
};

export default MaterialLoader;
