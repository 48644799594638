/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Components
import Dropdown from '../../../../components/custom/dropdown/general';
import TopProgressBar from '../../../../components/loader/topProgressBar';

// Actions
import { notificationSuccess, notificationError } from '../../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../../services/firebase';

// API Services
import EnquireAPI from '../../../../services/api/business/enquire';

// Options
const options = [
  {
    id: 'active',
    name: 'Active',
  },
  {
    id: 'closed',
    name: 'Closed',
  },
];

const Enquiries = ({ notificationError }) => {
  const history = useHistory();

  const [enquiries, setEnquiries] = useState([]);
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const state = localStorage.getItem(process.env.REACT_APP_CAREER_ENQUIRE_STATE);

    setActive(state ? Number(state) === 1 : 1);

    enquireList(true, state ? Number(state) : 1);
  }, []);

  // API Calls
  const enquireList = async (loader, active) => {
    if (loader) {
      setLoading(true);
    }

    try {
      const response = await EnquireAPI.list(active);
      const result = response.data;
      setEnquiries(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          enquireList(null, active);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="enquiries">
      {loading ? (
        <TopProgressBar />
      ) : (
        <Fragment>
          <div className="h-12 flex items-center justify-end">
            <Dropdown
              value={active ? 'Active' : 'Closed'}
              options={options}
              onSelect={(value) => {
                const active = value.name === 'Active';

                localStorage.setItem(process.env.REACT_APP_CAREER_ENQUIRE_STATE, active ? 1 : 0);

                setActive(active);
                enquireList(null, active ? 1 : 0);
              }}
            />
          </div>
          {enquiries.map((item, index) => {
            return (
              <div
                key={index}
                className="flex justify-start pt-3 pb-4 pl-3 shadow-md rounded-md border border-solid border-gray-200 relative mb-5 cursor-pointer hover:bg-gray-100 duration-700"
                style={{ height: 'fit-content' }}
                role="presentation"
                onClick={() => history.push(`/career-enquire/enquiries/${item.id}`)}
              >
                <span className="text-xl font-bold">#{index + 1}</span>
                <div className="flex flex-col ml-2">
                  <span className="text-sm font-medium">Date: {moment(item.createdAt).format('DD/MM/YYYY')}</span>
                  <span className="text-base font-semibold mt-1">{item.name}</span>
                  <span className="text-sm font-medium">{item.email}</span>
                  <button
                    className="text-white text-xs font-semibold rounded-full px-2 py-1 mt-2"
                    style={{ width: 'fit-content', backgroundColor: item.type.color }}
                  >
                    {item.type.tag}
                  </button>
                </div>
              </div>
            );
          })}
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Enquiries.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Enquiries);
