/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dispatch } from 'use-bus';
import { useHistory } from 'react-router-dom';
import numberFormat from 'indian-number-format';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';

// Components
import TopProgressBar from '../../../components/loader/topProgressBar';

// Actions
import { notificationSuccess, notificationError } from '../../../redux/root/actions';

// Firbase Services
import { currentUser } from '../../../services/firebase';

// API Services
import OrderAPI from '../../../services/api/business/order';

const Orders = ({ notificationSuccess, notificationError }) => {
  const history = useHistory();

  const [term, setTerm] = useState('');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    paid(true);
  }, []);

  // API Calls
  const paid = async (loader) => {
    if (loader) {
      setLoading(true);
    }

    try {
      const response = await OrderAPI.paid(true, true);
      const result = response.data;
      setOrders(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          paid(false);
        } else {
          notificationError(message);
        }
      }
    }
  };

  const search = async (term) => {
    try {
      const response = await OrderAPI.search(term);
      const result = response.data;
      setLoading(false);
      setOrders(result);
    } catch (error) {
      setLoading(false);

      if (error === process.env.REACT_APP_NETWORK_ERROR) {
        notificationError(error);
      } else {
        const {
          data: { message },
          status,
        } = error;

        if (status === HttpStatusCodes.FORBIDDEN) {
          dispatch(process.env.REACT_APP_LOG_USER_OUT);
        } else if (status === HttpStatusCodes.UNAUTHORIZED) {
          await currentUser().getIdToken(true);
          search(term);
        } else {
          notificationError(message);
        }
      }
    }
  };

  return (
    <div className="orders pt-32">
      {loading ? (
        <TopProgressBar />
      ) : (
        <div className="container">
          <div className="row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 mb-5 cursor-pointer"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              onClick={() => history.goBack()}
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path>
            </svg>
          </div>
          <div className="row">
            <div className="w-full h-12 flex items-center justify-end relative mb-4">
              <div className="search-box">
                <input
                  className="search-input"
                  type="text"
                  placeholder="Search By ID"
                  value={term}
                  onChange={(event) => {
                    const value = event.target.value;

                    search(value);
                    setTerm(value);
                  }}
                ></input>
                <span className="search-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          {orders.map((item, index) => {
            return (
              <div key={index} className="row">
                <div
                  key={index}
                  className="w-full flex justify-start pt-3 pb-4 pl-3 shadow-md rounded-md border border-solid border-gray-200 relative mb-5 cursor-pointer hover:bg-gray-100 duration-700"
                  style={{ height: 'fit-content' }}
                  role="presentation"
                  onClick={() => history.push(`/orders/details/${item.id}`)}
                >
                  <span className="text-xl font-bold">#{index + 1}</span>
                  <div className="flex flex-col ml-2">
                    <span className="text-sm font-medium">Date: {item.date}</span>
                    <span className="text-sm font-semibold">Order #{item.id}</span>
                    <span className="text-base font-semibold mt-1">{item.name}</span>
                    <span className="text-base font-semibold mt-1">
                      Items: {item.items}, Cost: &#8377;{numberFormat.format(Number(item.total).toFixed(2))},
                      Convenience Fee: &#8377;{numberFormat.format(Number(item.convenienceFee).toFixed(2))}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  notificationSuccess: (message) => dispatch(notificationSuccess(message)),
  notificationError: (message) => dispatch(notificationError(message)),
});

Orders.propTypes = {
  session: PropTypes.object,
  notificationSuccess: PropTypes.func,
  notificationError: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
